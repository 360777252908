import React from "react";
// import ReactDOM from "react-dom";
import BannerSection from "./newbannersection.js";
import TabSection from "./tabsection.js";
import ClientSection from "./clientsection.js";
import StrategiesSection from "./strategiessection.js";
import LetsgetSection from './letsgetsection.js';
// import LatestSEOSection from './latestseo.js';
import  FooterSection from './footerSection.js';
import './banner.css';
function LandingPage() {
  return (
    <>
    <div className="Eso_S_Landing_page">
      <BannerSection />
      <TabSection />
      <ClientSection />
      <StrategiesSection />
      <LetsgetSection />
      {/* <LatestSEOSection /> */}
      <FooterSection />
   </div> 

    </>
  );
}

export default LandingPage;