import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { HashLink } from 'react-router-hash-link';
import contact_us_bg from './../../images/eso/home_image/contact_banner_two.png'
import bg_3 from '../../images/eso/home_image/bg_3.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';
import errorimg from '../../images/PNG/Error 404.png';

import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Errorpage = () => {

  const [loading, setLoading] = useState(false);
    return (
      <>
      <div id="et-main-area">
  <div className="container page_error top_padding_50 bottom_padding_50">
    <div className="row">
      <div className="col-12">
        <div className="common_text_inner common_center">
          <img
            src={errorimg}
            alt={404}
            style={{ maxWidth: 400, margin:'0 auto' }}
          />
          <h4 className="top_margin_50" style={{ textAlign: "justify" }}>
            Unfortunately, we had a problem processing your request. Please try
            again, we will seriously investigate the issue and fix it.
          </h4>
          <p
            className="btn_blw_wrap"
            style={{
              color: "#05173e",
              fontSize: 20,
              fontFamily: "sans-serif",
              marginTop: 10
            }}
          >
            for further queries please contact{" "}
            <strong style={{}}>
              <a
                href="mailto:support@elitesiteoptimizer.com"
                style={{ color: "#05173e", textDecoration: "none" }}
              >
                support@elitesiteoptimizer.com
              </a>
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

      </>
    );
  }

export default Errorpage;