import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import ESO_Lan_Shape_1 from './../../images/Images/Footer/Shape_1.png';
import ESO_Lan_Forma_1 from './../../images/Images/Footer/Forma_1.png';
import ESO_Lan_bg_rocket from './../../images/Images/footer_bg_rocket.png';
import ESO_Lan_footer_robot from './../../images/Images/footer_robot.png';

function FooterSection(){
    const currentDate = new Date();
     // Get the current year
  const currentYear = currentDate.getFullYear();
    return(
        
        <>
        <div className="footer_outer_wrap">
        <div className="footer_inner_container ">
        <div className="footer_inner_container_top container">
        <div className="footer_inner_cnt_fea">
        <h6 className='ftr_ttl'>Features</h6>
        <ul className='ftr_grp_wrap'>
        <li> <Link to="/features/seo-dashboard/" state={{ from: `ecommerce-business` }} elementId='root'>SEO Dashboard</Link> </li>
        <li> <Link to="/features/webpage-seo-analysis/" state={{ from: `ecommerce-business` }} elementId='root'>Performance Analysis</Link> </li>
        <li> <Link to="/features/keyword-ranking-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Rank Analysis</Link> </li>
        <li> <Link to="/features/on-page-seo-analysis/" state={{ from: `ecommerce-business` }} elementId='root'>On Page SEO Analysis</Link> </li>
        <li> <Link to="/features/internal-link-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Link Checker</Link> </li>
        <li> <Link to="/features/schema-validator/" state={{ from: `ecommerce-business` }} elementId='root'>Schema Validator</Link> </li>
        <li> <Link to="/features/traffic-analytics/" state={{ from: `ecommerce-business` }} elementId='root'>Analytics Console</Link> </li>
        <li> <Link to="/features/sitemap-generator/" state={{ from: `ecommerce-business` }} elementId='root'>Sitemap Generator</Link> </li>
        <li> <Link to="/features/content-audit/" state={{ from: `ecommerce-business` }} elementId='root'>Content Audit</Link> </li>
        <li> <Link to="/features/plagiarism-checker/" state={{ from: `ecommerce-business` }} elementId='root'>Plagiarism Checker</Link> </li>
        </ul>
        </div>
        {/* <div className="footer_inner_cnt_sol">
        <h6 className='ftr_ttl'>Solutions</h6>
        <ul className='ftr_grp_wrap'>
        <li> <Link to="/solutions/ecommerce-business" state={{ from: `ecommerce-business` }} elementId='root'>Ecommerce Business</Link> </li>
        <li> <Link  to="/solutions/restaurants-seo/" state={{ from: `restaurants-seo` }} elementId='root' >Restaurant SEO</Link> </li>
        <li> <Link  to="/solutions/digital-marketing/" state={{ from: `digital-marketing` }} elementId='root'>Digital Marketing</Link> </li>
        <li> <Link  to="/solutions/enterprise-brands/" state={{ from: `enterprise-brands` }}elementId='root' >Enterprise Brands</Link> </li>
        <li> <Link  to="/solutions/b2g-seo/" state={{ from: `b2g-seo` }} elementId='root'>B2G SEO</Link> </li>
        <li> <Link to="/solutions/personal-bloggers/"  state={{ from: `personal-bloggers` }} elementId='root'>Personal Bloggers</Link> </li>
        <li> <Link  to="/solutions/news-content-publishers/" state={{ from: `news-content-publishers` }} elementId='root'>News &amp; Content Publishers</Link> </li>
        <li> <Link  to="/solutions/web-hosting/" state={{ from: `web-hosting` }} elementId='root'>Web Hosting Seo Toolkit</Link> </li>
        </ul>
        </div> */}
        <div className="footer_inner_cnt_abt" style={{marginLeft:"60px"}}>
        <div className='footer_inner_wrp_abt'>
        <h6 className='ftr_ttl'>About Us</h6>
        <ul className='ftr_grp_wrap'>
        <li> <HashLink to="/terms-conditions/" elementId='root'>Terms &amp; Conditions</HashLink> </li>
        <li> <HashLink to="/privacy-policy/" elementId='root'>Privacy Policy</HashLink> </li>
        <li> <HashLink to="/disclaimer/" elementId='root'>Disclaimer</HashLink> </li>
        <li> <HashLink to="/about-us/" elementId='root'>About us</HashLink> </li>
        </ul>
        </div>
        <div className='footer_inner_wrp_sol'>
        <h6 className='ftr_ttl'>Location</h6>
        <p  className='ftr_addr'> Elite Site Optimizer Inc.<br></br> 6G Auer Ct. <br></br>East Brunswick, NJ 08816
        </p>
        </div>
        </div>
        <div className="footer_inner_cnt_res " style={{marginLeft:"60px"}}>
        <div className='footer_inner_wrp_res'>
        <h6 className='ftr_ttl'>Resources</h6>
        <ul className='ftr_grp_wrap'>
        <li> <HashLink to="/blog/" elementId='root'>Blog</HashLink> </li>
        <li> <HashLink to="/site-map/" elementId='root'>Site Map</HashLink> </li>
        {/* <li> <HashLink to="/kb"  elementId='root'>Knowledgebase</HashLink> </li> */}
        </ul>
        </div>
        <div className='footer_inner_wrp_fu'>
        <h6 className='ftr_ttl'>Follow Us</h6>
        <div className='ftr_social_share '>

        
        <a href="https://www.facebook.com/elitesiteoptimizer">
        <FontAwesomeIcon icon={faSquareFacebook} />
        </a>
        <a href="https://twitter.com/eliteseomanager">
        <FontAwesomeIcon icon={faSquareInstagram} />
        </a>
        <a href="https://www.instagram.com/elitesiteoptimizer/">
        <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://www.linkedin.com/company/elite-site-optimizer/">
        <FontAwesomeIcon icon={faSquareXTwitter} size="1x" />
        </a>
        <a href="https://www.youtube.com/channel/UCCdvvASrYRheEzAnbinh_Ow/videos">
        <FontAwesomeIcon icon={faSquareYoutube} />
        </a>
        </div>
        <div className='Ftr_dmca_outer_wrap'>
        <div className='Ftr_dmca_innr_wrap'>
        <p className='Ftr_dmca_outer_dmca ftr_cert'>DMCA</p>
        <p className='Ftr_dmca_outer_prt ftr_cert'>Protection</p>
        </div>
        </div>
        </div>
        </div>

        </div>
        <div className="ftr_bg_img_grp">
        <img src={ESO_Lan_Shape_1} className="ftr_shape_1" alt="footer_bg_img icons"/>
        <img src={ESO_Lan_Forma_1} className="ftr_forma" alt="footer_bg_img icons"/>
        <img src={ESO_Lan_bg_rocket} className="ftr_rck" alt="footer_bg_img rocket"/>
        <img src={ESO_Lan_footer_robot} className="ftr_robot" alt="footer_bg_img robots"/>
        </div>
        <div className="footer_inner_container_btm">
        <p>All Rights Reserved. Copyright © 2015 - {currentYear} Elite Site Optimizer</p>
        </div>
        </div>
        </div>
        </>
    );

}


    export default FooterSection;