import React, { useEffect } from 'react';
import './customcrawler.css';
import { HashLink } from 'react-router-hash-link';
import Bg4 from './../../../images/eso/home_image/bg_4.png';
import Bg3 from './../../../images/eso/home_image/bg_3.png';
import Custom2 from './../../../images/eso/home_image/custom_2.png'
import Customcrawlerbanner from './../../../images/eso/home_image/custom_crawler_banner.png'

const CustomCrawler = () => {
    useEffect(() => {
        document.title = 'Custom Crawler | Web Scraping | Elite Site Optimizer';
    }, []);
    return (
        <div>
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                    <div className="container">
                        <div className="page_title_img_wrap">
                            <img src={Customcrawlerbanner} alt="custom_crawler_banner" />
                        </div>
                        <h2>Custom Crawler</h2>
                        <h1>Shape The Future</h1>
                        <h3>Of Your Online Presence</h3>
                    </div>
                </div>
                {/* End Page Title */}
                <div className="container">
                    <div className="custom_crawler_detail_wrap">
                        <h4>Scan your website for errors or scrape data from across the Web to improve your “business.”</h4>
                        <h5>Custom Crawlers Are Problem Solvers</h5>
                        <p>We build custom web crawlers to help you extract your website issues, whether you need the site crawled during the development stage, staging server, or in live scenarios. Custom crawlers also help you in finding broken links (404s), server errors, and SEO gaps that are crucial to maximize your ROI. They can also find redirect loops during site migration. When we create a custom crawler, we design it according to your needs and set a scalable crawl limit you choose.</p>
                        <div className="custom_crawler_detail_image"><img src={Custom2} alt="custom_crawler_detail" /></div>
                    </div>
                    {/* Steps and process */}
                    <div className="custom_crawler_steps_center_col_wrap custom_crawler_steps_common_wrap desktop-hide">
                                <h4>Custom Crawlers Do More Than Out-Of-The-Box Options</h4>
                                <h5>Step &amp; Process</h5>
                            </div>
                    <div className="custom_crawler_steps_outer_wrap">
                        <div className="custom_crawler_steps_left_col_wrap custom_crawler_steps_common_wrap">
                            <div className="custom_crawler_steps_inner_wrap">
                                <p className="eso_web_data">Build a substantial web data within minutes</p>
                                <p className="eso_research">Shape your data models to enhance your research</p>
                            </div>
                            <div className="custom_crawler_steps_center_col_wrap custom_crawler_steps_common_wrap mobile-hide">
                                <h4>Custom Crawlers Do More Than Out-Of-The-Box Options</h4>
                                <h5>Step &amp; Process</h5>
                            </div>
                        </div>
                        <div className="custom_crawler_steps_right_col_wrap custom_crawler_steps_common_wrap">
                            <div className="custom_crawler_steps_inner_wrap">
                                <p className="eso_info_decision">Forecast better &amp; make informed decision</p>
                                <p className="eso_format">Extract data to analyze in your preferred format</p>
                            </div>
                        </div>
                    </div>
                    {/* End Steps */}
                    <div className="custom_crawler_services_outer_wrap">
                        <div className="custom_crawler_services_left_wrap">
                            <h3>Custom Crawlers</h3>
                            <h4>By Our Engineers</h4>
                        </div>
                        <div className="custom_crawler_services_right_wrap">
                            <div className="custom_crawler_services_inner_wrap">
                                <p className="eso_price_comparison custom_crawler_services_icon_wrap" aria-label="price_comparison">&nbsp;</p>
                                <h5>Price<br />Comparison</h5>
                                <p>Check prices from competitor websites through automated bots to offer a better price</p>
                            </div>
                            <div className="custom_crawler_services_inner_wrap">
                                <p className="eso_website_content_aggregate custom_crawler_services_icon_wrap" aria-label="website_content_aggregator">&nbsp;</p>
                                <h5>Website Content <br />Aggregator</h5>
                                <p>Use Custom Content Crawlers to aggregate news feed, events, social data, &amp; job information.</p>
                            </div>
                            <div className="custom_crawler_services_inner_wrap">
                                <p className="eso_seo_information custom_crawler_services_icon_wrap" aria-label="price_comparison">&nbsp;</p>
                                <h5>SEO<br />Information</h5>
                                <p>Gather SEO information for your website and competitor websites to define a stronger SEO strategy.</p>
                            </div>
                            <div className="custom_crawler_services_inner_wrap">
                                <p className="eso_product_catalog custom_crawler_services_icon_wrap" aria-label="price_comparison">&nbsp;</p>
                                <h5>Product<br />Catalog Builder</h5>
                                <p>Use Custom Crawlers to aggregate product information from supplier websites.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CTA */}
                <div className="cta_bg_wrap">
                    <div className="cta_bg_img_left_outer_wrap">
                        <img src={Bg4} alt="CTA_bg" />
                    </div>
                    <div className="cta_bg_img_right_outer_wrap">
                        <img src={Bg3} alt="CTA_bg" />
                    </div>
                    <div className="container">
                        <div className="cta_content_outer_wrap">
                            <h4><span>Schedule A Meeting</span> To Take<br />Your Business To The Next Level.</h4>
                            <HashLink to="/contact-us/?subject=free-trial-request" elementId='contact_form_test'>Get Your Free Trial</HashLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`}  />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> */}
             {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default CustomCrawler;