import { useEffect, useState,  useRef  } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";
import { RotatingLines } from 'react-loader-spinner';
//https://mhnpd.github.io/react-loader-spinner/docs/components/rotating-lines
import './report-table.css';


const ResultTable = () => {
    const mainRef = useRef(null); 
    const [style, setStyle] = useState("cont");
    const [style1, setStyle1] = useState("cont");
    const [style2, setStyle2] = useState("cont");
    const [style3, setStyle3] = useState("cont");
    const [style4, setStyle4] = useState("cont");
    const [style5, setStyle5] = useState("cont");
    const [style6, setStyle6] = useState("cont");

    const changeStyle = () => {
        setStyle("cont2");
        setStyle1("cont");
        setStyle2("cont");
        setStyle3("cont");
        setStyle4("cont");
        setStyle5("cont");
        setStyle6("cont");
    };

    const changeStyle1 = () => {
        setStyle1("cont2");
        setStyle("cont");
        setStyle2("cont");
        setStyle3("cont");
        setStyle4("cont");
        setStyle5("cont");
        setStyle6("cont");
    };

    const changeStyle2 = () => {
        setStyle2("cont2");
        setStyle1("cont");
        setStyle("cont");
        setStyle3("cont");
        setStyle4("cont");
        setStyle5("cont");
        setStyle6("cont");
    };

    const changeStyle3 = () => {
        setStyle3("cont2");
        setStyle2("cont");
        setStyle1("cont");
        setStyle("cont");
        setStyle4("cont");
        setStyle5("cont");
        setStyle6("cont");
    };

    const changeStyle4 = () => {
        setStyle4("cont2");
        setStyle3("cont");
        setStyle2("cont");
        setStyle1("cont");
        setStyle("cont");
        setStyle5("cont");
        setStyle6("cont");
    };

    const changeStyle5 = () => {
        setStyle5("cont2");
        setStyle4("cont");
        setStyle3("cont");
        setStyle2("cont");
        setStyle1("cont");
        setStyle("cont");
        setStyle6("cont");
    };

    const changeStyle6 = () => {
        setStyle6("cont2");
        setStyle5("cont");
        setStyle4("cont");
        setStyle3("cont");
        setStyle2("cont");
        setStyle1("cont");
        setStyle("cont");
    };

    const navigate = useNavigate();
    const [loadingPlag, setLoadingPlag] = useState(false)
    const [loadingCode, setLoadingCode] = useState(false)
    const [loadingSpell, setLoadingSpell] = useState(false)
    const [loadingSchema, setLoadingSchema] = useState(false)
    const [loadingContent, setLoadingContent] = useState(false)
    const [loadingCrawl, setLoadingCrawl] = useState(false)
    const [callModal, setCallModal] = useState(false)
    const [showButton, setShowButton] = useState(false)


    const userData = {
        user_url: localStorage.getItem('userUrl'),
        user_email: localStorage.getItem('userEmail')
    }

    const instantApiUrl = `${process.env.REACT_APP_DOMAIN}/trial-report-api`

    const codeAuditDetailReport = async () => {
        setLoadingCode(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-code-audit`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingCode(false)
    }

    const plagiarismDetailReport = async () => {
        setLoadingPlag(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-plagiarism`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingPlag(false)
    }

    const spellCheckerDetailReport = async () => {
        setLoadingSpell(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-spellchecker`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingSpell(false)
    }

    const schemaValidatorDetailReport = async () => {
        setLoadingSchema(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-schema-validator`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingSchema(false)
    }

    const contentAuditDetailReport = async () => {
        setLoadingContent(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-content-audit`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingContent(false)
    }

    const onPageDataDetailReport = async () => {
        setLoadingCrawl(true)
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-detail-report-api-for-on-page-data`, userData).then((res) => {
            if (res.status === 200) { setCallModal(true) }
        })
        setLoadingCrawl(false)
    }

    const checkFun = () => {
        setCallModal(true)
    }

    
    // const AvgSentenceCount = axios.get(instantApiUrl).then((response16) => { return (response16.data.readability_report.Avg_sentance_count) })
    const [readabilityScore, setReadabilityScore] = useState(null)
    const [readingEasiness, setReadingEasiness] = useState(null)
    const [gradeLevel, setGradeLevel] = useState(null)
    const [totalSentence, setTotalSentence] = useState(null)
    const [totalWords, setTotalWords] = useState(null)
    const [avgSentence, setAvgSentence] = useState(null)
    const [errors, setErrors] = useState(null)
    const [fatalErrors, setFatalErrors] = useState(null)
    const [warnings, setWarnings] = useState(null)
    const [altMissing, setAltMissing] = useState(null)
    const [noIndexRobot, setNoIndexRobot] = useState(null)
    const [canonical, setCanonical] = useState(null)
    const [hreflang, setHreflang] = useState(null)
    const [description, setDescription] = useState(null)
    const [h1, setH1] = useState(null)
    const [paginationPrevNext, setPaginationPrevNext] = useState(null)
    const [img, setImg] = useState(null)
    // const [positionStatus, setPositionStatus] = useState(null)
    const [richSnippet, setRichSnippet] = useState(null)
    const [misspelledCount, setMisspelledCount] = useState(null)
    const [misspelledWords, setMisspelledWords] = useState(null)
    const [title, setTitle] = useState(null)
    const [countWords, setCountWords] = useState(null)
    // const [totalSimilarityFound, setTotalSimilarityFound] = useState(null)
    const [allWordsMatched, setAllWordsMatched] = useState(null)
    const [similarityFound, setSimilarityFound] = useState(null)
    const [passedCount, setPassedCount] = useState(null)
    const [failedCount, setFailedCount] = useState(null)
    const [plagiarismError, setPlagiarismError] = useState(undefined)

    useEffect(() => {
       // .current.scrollTo({behavior: 'smooth'})
        window.scrollTo({
            top: mainRef.current.offsetTop,
            behavior: 'smooth',
          }); 
      }, [])

    useEffect(() => {
       
        const apiResponse = axios.post(instantApiUrl, userData).then((response) => {
            if (response.status === 200) {
                console.log(response.data);
                setReadabilityScore(response.data.readability_report.content_readability_score)
                setReadingEasiness(response.data.readability_report.read_ease)
                setGradeLevel(response.data.readability_report.grad_level)
                setTotalSentence(response.data.readability_report.no_of_sentances)
                setTotalWords(response.data.readability_report.count_words)
                setAvgSentence(response.data.readability_report.Avg_sentance_count)
                setErrors(response.data.code_validation_report.errors)
                setFatalErrors(response.data.code_validation_report.fatal_errors)
                setWarnings(response.data.code_validation_report.warnings)
                setAltMissing(response.data.on_page_crawl_data.alt_missing)
                setNoIndexRobot(response.data.on_page_crawl_data.no_index)
                setCanonical(response.data.on_page_crawl_data.canonical)
                setHreflang(response.data.on_page_crawl_data.hreflang)
                setDescription(response.data.on_page_crawl_data.description)
                setTitle(response.data.on_page_crawl_data.title)
                setH1(response.data.on_page_crawl_data.h1)
                setPaginationPrevNext(response.data.on_page_crawl_data.pagination_prev_next)
                setImg(response.data.on_page_crawl_data.img)
                // setPositionStatus(response.data.ranking_report.position_status)
                setMisspelledCount(response.data.spell_checker_report.misspelled_count)
                setMisspelledWords(response.data.spell_checker_report.misspelled_words)
                setCountWords(response.data.plagiarism_check.response.querywords)
                // setTotalSimilarityFound(response.data.plagiarism_check.response.alltextmatched)
                setAllWordsMatched(response.data.plagiarism_check.response.allwordsmatched)
                setSimilarityFound(response.data.plagiarism_check.response.count)

                setRichSnippet(response.data.schema_validator.rich_snippet)
                setFailedCount(response.data.schema_validator.failed_count)
                setPassedCount(response.data.schema_validator.passed_count)
    
                setPlagiarismError(response.data.plagiarism_check.response.error)
                setShowButton(true)
            }
          
        })
    }, [])

   
    return (
        <div className='table-container' ref={mainRef}>
            <Table className='my-table table-striped table-secondary'>
                <thead>
                    <tr className='table-header'>
                        <th style={{ color: '#052A53' }} className='s-no' >No</th>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <th key={index}>Report</th>
                    ))} */}
                        <th></th>
                        <th style={{ color: '#052A53' }} className='report'>Audit Items</th>
                        <th style={{ color: '#052A53' }} className='remarks'>Results</th>
                        <th className='empty-header-1'></th>
                        <th className='empty-header-2'></th>
                        <th className='empty-header-3'></th>
                        <th className='action'></th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className='no'>1</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>Content Audit</td>
                        <td className='results'><div className='result-container'>Readability score&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{readabilityScore === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : readabilityScore}</span></div>
                            <div className='result-container'>Reading Easiness &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{readingEasiness === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : readingEasiness}</span></div>
                            <div className='result-container'>Grade Level       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{gradeLevel === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : gradeLevel}</span></div>

                        </td>
                        <td className='results'><div className='result-container'>Total Sentence    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:  &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{totalSentence === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : totalSentence}</span></div>
                            <div className='result-container'>Total Words      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{totalWords === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : totalWords}</span></div>
                            <div className='result-container'>Average Sentence Length :  &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{avgSentence === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : avgSentence}</span></div></td>
                        {/* <td></td> */}
                        <td></td>
                        <td>
                            {/* <HashLink>
                                <a className='view-report' onClick={contentAuditDetailReport} disabled={loadingContent} d="getblogpagnination">
                                    {loadingContent && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingContent && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingContent && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </a>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={contentAuditDetailReport} disabled={loadingContent} d="getblogpagnination" className='view-report'>
                                {loadingContent && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingContent && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingContent && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <br />
                        <br />
                        <div className={style}>&nbsp;Please Sign up<br />
                            or Login to view 
                        </div>
                    </td> */}
                        <td></td>
                    </tr>
                    <tr>
                        <td className='no'>2</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>On-Page Status</td>
                        <td>
                            {/* <div className='result-container'>Schema Markup Issues &nbsp;&nbsp;&nbsp;&nbsp;: </div>{ } */}
                            <div className='result-container'>No-index Robot &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{noIndexRobot === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : noIndexRobot}</span></div>
                            <div className='result-container'>Missing Canonical &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{canonical === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : canonical}</span></div>
                            <div className='result-container'>Hreflang &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{hreflang === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : hreflang}</span></div>
                            {/* <div className='result-container'>Duplicate Description &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{description === null ? 'loading...' : description}</span></div> */}
                        </td>
                        <td><div className='result-container'>Image Available &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{img === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : img}</span></div>
                            <div className='result-container'>Missing Title &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{title === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : title != '' ? 0 : title}</span></div>
                            <div className='result-container'>H1 Tags Available &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{h1 === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : h1}</span></div>
                            {/* Duplicate H1: { }<br />
                        Backlinks: { }<br /> */}
                        </td>
                        <td><div className='result-container'>Missing ALT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{altMissing === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : altMissing}</span></div>
                            <div className='result-container'>Missing Description &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{description === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : description != ' ' ? 0 : description}</span></div>
                            <div className='result-container'>Pagination Next or Prev : &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{paginationPrevNext === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : paginationPrevNext}</span></div>
                            {/* Duplicate Title: { }<br /> */}
                        </td>
                        <td>
                            {/* <HashLink>
                                <a className='view-report' onClick={onPageDataDetailReport} disabled={loadingCrawl} d="getblogpagnination">
                                    {loadingCrawl && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingCrawl && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingCrawl && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </a>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={onPageDataDetailReport} disabled={loadingCrawl} d="getblogpagnination" className='view-report'>
                                {loadingCrawl && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingCrawl && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingCrawl && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <td><HashLink to='/accounts/login/' onClick={changeStyle1} style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style1}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> */}
                        <td></td>
                    </tr>
                    <tr>
                        <td className='no'>3</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>Schema</td>
                        <td><div className='result-container'>List of Rich Snippet Items &nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{richSnippet === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : richSnippet}</span></div>
                            <div className='result-container'>Passed Schema Count &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{passedCount === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : passedCount}</span></div>
                            <div className='result-container'>Failed Schema Count &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{failedCount === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : failedCount}</span></div>
                            {/* Missing Count: { }<br />
                        Warning Count: { }<br /> */}
                        </td>
                        <td></td>
                        {/* <td></td> */}
                        <td></td>
                        <td>
                            {/* <HashLink>
                                <a className='view-report' onClick={schemaValidatorDetailReport} disabled={loadingSchema} d="getblogpagnination">
                                    {loadingSchema && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingSchema && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingSchema && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </a>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={schemaValidatorDetailReport} disabled={loadingSchema} d="getblogpagnination" className='view-report'>
                                {loadingSchema && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingSchema && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingSchema && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <td><HashLink to='/accounts/login/' onClick={changeStyle2} style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style2}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> */}
                        <td></td>
                    </tr>
                    <tr>
                        <td className='no'>4</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>Plagiarism</td>
                        <td><div className='result-container'>Total Words &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{countWords === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : plagiarismError === undefined ? countWords : 'NA'}</span></div>
                            {/* <div className='result-container'>Total Similarity Found &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{color: 'black', fontWeight: '400'}}>{totalSimilarityFound === null ? 'loading...' : totalSimilarityFound}</span></div> */}
                            <div className='result-container'>Total Matches Found &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;<span style={{ color: 'black', fontWeight: '400' }}>{allWordsMatched === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : plagiarismError === undefined ? allWordsMatched : 'NA'}</span></div>
                            <div className='result-container'>Similarity Score &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;<span style={{ color: 'black', fontWeight: '400' }}>{similarityFound === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : plagiarismError === undefined ? similarityFound : 'NA'}</span></div>
                            {/* <div className='result-container'>Details Similarity Matches: { }</div> */}

                        </td>
                        <td></td>
                        {/* <td></td> */}
                        <td></td>
                        <td>
                            {/* <HashLink>
                                <a className='view-report' onClick={plagiarismDetailReport} disabled={loadingPlag} d="getblogpagnination">
                                    {loadingPlag && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingPlag && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingPlag && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </a>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={plagiarismDetailReport} disabled={Boolean(plagiarismError) && !loadingPlag} d="getblogpagnination"  className={plagiarismError ? 'view-report-disable':'view-report'}>
                                {loadingPlag && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingPlag && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingPlag && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <td><HashLink to='/accounts/login/' onClick={changeStyle3} style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style3}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> */}
                        <td></td>
                    </tr>
                    <tr>
                        <td className='no'>5</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>Code Audit</td>
                        <td><div className='result-container'>Code Fatal Error &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{fatalErrors === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : fatalErrors}</span></div>
                            <div className='result-container'>Code Warnings &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{warnings === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : warnings}</span></div>
                            <div className='result-container'>Errors   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{errors === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : errors}</span></div>
                        </td>
                        <td></td>
                        {/* <td></td> */}
                        <td></td>
                        <td>
                            {/* <HashLink>
                                <a className='view-report' onClick={codeAuditDetailReport} disabled={loadingCode} d="getblogpagnination">
                                    {loadingCode && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingCode && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingCode && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </a>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={codeAuditDetailReport} disabled={loadingCode} d="getblogpagnination" className='view-report'>
                                {loadingCode && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingCode && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingCode && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <td><HashLink to='/accounts/login/' onClick={changeStyle4} style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style4}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> */}
                        <td></td>
                    </tr>

                    {/* <tr>
                    <td>6</td>
                    {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} 
                    <td>Ranking</td>
                    <td><div className='result-container'>Keyword &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{k}</span></div>
                        <div className='result-container'>Preferred Url &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{u}</span></div>
                        <div className='result-container'>Preferred Url Position &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{positionStatus === null ? 'loading...' : positionStatus}</span></div>
                        <div className='result-container'>Track Url &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{errors === null ? 'loading...' : errors}</span></div>{ }
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td><HashLink to='/accounts/login/' onClick={changeStyle5} style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style5}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> 
                </tr> */}

                    <tr>
                        <td className='no'>6</td>
                        {/* {Array.from({ length: 12 }).map((_, index) => (
                        <td key={index}>Table cell </td>
                    ))} */}
                        <th></th>
                        <td className='topics'>Spell Checker</td>
                        <td>
                            {/* <div className='result-container'>Misspelled Words: <br /></div> */}
                            {/* {misspelledWords === null ? 'loading...' : misspelledWords.map((items, index)=>{
                                    return (<ol>
                                                {items.map((subitems, subindex) => {
                                                    return <li key={subindex}>{subitems}</li>
                                                })}
                                            </ol>)
                                })} */}
                            {/* {misspelledWords === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : misspelledWords.map((items, index) => {
                                return (<div>
                                    {items + ''}
                                </div>)
                            })} */}
                            {/* {misspelledWords === null ? 'loading...' : misspelledWords} */}
                            <div className='result-container'>Misspelled Count: &nbsp; <span style={{ color: 'black', fontWeight: '400' }}>{misspelledCount === null ? <RotatingLines strokeColor="#052A53" strokeWidth="3" animationDuration="0.75" visible={true} width="18"/> : misspelledCount}</span></div>
                            {/* {misspelledCount === null ? 'loading...' : misspelledCount}<br /> */}
                            {/* Suggested Words: { }<br /> */}
                        </td>
                        <td></td>
                        {/* <td></td> */}
                        <td></td>
                        <td>
                            {/* <HashLink>
                                <button className='view-report' onClick={spellCheckerDetailReport} disabled={loadingSpell} d="getblogpagnination" data-toggle="modal" data-target="#exampleModalCenter">
                                    {loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                    {loadingSpell && (<i className="fa fa-spinner fa-spin" />)}
                                    {!loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                </button>
                            </HashLink> */}
                            {showButton ? <button type="button" onClick={spellCheckerDetailReport} disabled={loadingSpell} d="getblogpagnination" className='view-report'>
                                {loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingSpell && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> : ''}
                        </td>
                        {/* <td><HashLink to='/accounts/login/' onClick={changeStyle6}style ={{paddingTop: '5px'}} className='view-report'>View Report</HashLink><br />
                        <br />
                        <div className={style6}>&nbsp;Please Sign up<br />
                        or Login to view </div>
                    </td> */}
                        <td></td>
                    </tr>
                    {/* <tr>
                    <td ></td>
                    <td></td>
                    <td className='last-row'>**Note: NA is Not Available</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> */}
                </tbody>
            </Table>
            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                Launch demo modal
            </button> */}

            {/* <!-- Modal --> */}
            {/* <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content" >
                        <img src={thankYouModal} alt='thank_you_modal' />
                        <h2>Thank You!</h2>
                        <div class='center' >
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal show={callModal} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Title style={{ background: '#052A53', display: 'flex', justifyContent: 'space-between', padding: '8px'}}><span style={{ color: '#fff', fontFamily: "sans-serif", marginLeft: '35%'}}>Thank You! </span><button style={{ padding: '6px', height: '8px', width: '8px' }} onClick={() => { setCallModal(false) }} type="button" class="btn-close btn-close-white" aria-label="Close"></button></Modal.Title>
                <Modal.Body>
                    <h5 style={{ color: '#052A53', fontFamily: "sans-serif" }}>We have sent Detailed report to your Email, Please check.</h5>
                    <button style={{marginLeft: '43%', background: '#052A53', color: '#fff', borderWidth: '0px', borderRadius: '5px', fontFamily: "sans-serif" }} onClick={() => { setCallModal(false) }} variant="secondary">Okay</button>
                </Modal.Body>
            </Modal>

            {/* <button type="button" onClick={checkFun} disabled={loadingSpell} d="getblogpagnination" className='view-report'>
                                {loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                                {loadingSpell && (<i className="fa fa-spinner fa-spin" />)}
                                {!loadingSpell && <span style={{ color: 'white', alignContent: 'center' }}>Detailed Report</span>}
                            </button> */}

            {/* <Modal show={callModal} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Title style={{background: '#052A53', display: 'flex', justifyContent: 'space-between', padding: '8px'}}><span style={{color: '#fff'}}>Thank You! </span><button style={{padding: '6px', height: '8px', width: '8px'}} onClick={() => { setCallModal(false) }} type="button" class="btn-close btn-close-white" aria-label="Close"></button></Modal.Title> 
                <Modal.Body>
                    <h5 style={{ color: '#052A53' }}>We have sent Detailed report to your Email, Please check.</h5>
                    <button style={{float: 'right', background: 'orange', color: '#052A53', borderWidth: '0px', borderRadius: '5px'}} onClick={() => { setCallModal(false) }} variant="secondary">Close</button>
                </Modal.Body>
            </Modal> */}

            <div className='last-row'>**Note: NA is Not Available</div>
        </div>
    )
}

export default ResultTable;