import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
// import parseRange from 'http-range-parse';
import './blog.css';
import './hfstyle.css';
import EditorsPick from './staticTopics/EditorsPick';
import HottestTopics from './staticTopics/HottestTopics';
import LatestTopics from './staticTopics/LatestTopics';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const Blog = () => {

    useEffect(() => {
        document.title = 'SEO Audit &amp; Reporting Tool | SEO Automation Tool | Official Blog';
        // window.scrollTo(0, 0)
        
    }, []);

    
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [token, setToken] = useState()
    const [cardData, setCardData] = useState([])
    const [search, setSearch] = useState('')
    const [showData, setShowData] = useState(true)
    const [searchTitle, setSearchTitle] = useState("Editor’s pick")
    const [searchData, setSearchData] = useState([])
    const [showSearchData, setShowSearchData] = useState(false)
    const [resultData, setResultData] = useState([])
    const [sliceCountOne, setSliceCountOne] = useState(0)
    const [sliceCountTwo, setSliceCountTwo] = useState(6)
    
    // const [detailUrl, setDetailUrl] = useState()
    const [loading, setLoading] = useState(false)   

    const getBannerimages = async () =>{
        const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
        }
    
       const response_data= await axios.get(`${process.env.REACT_APP_BLOGS_API}/blog-banners`, dataParams)
       setCardData(response_data.data) 
    }
    useEffect(() => {
        getBannerimages();
    }, [])
    return (
        <>
        <div className='carousel_manual'>
        <Carousel autoPlay interval="5000"  infiniteLoop>
        {cardData.length > 0 && cardData.map((item, index) => (
                 <div>
                <img src={process.env.REACT_APP_BLOGS_API + item?.Banner_Images?.url}
                 alt={process.env.REACT_APP_BLOGS_API + item?.Alt_Name} title="Elitesiteoptimizer - Blogs"  />
                  </div>
               ))}
   </Carousel>
</div>
            <section className="py-4 container">
                <div>
                    {/* #main-header */}
                    <div id="et-main-area">
                        <div id="content" className="bgs-site-content container">
                            <div className="bgs-module   bgs-module-layout-combo">
                                {showData ? <div id="bgs-module-2" className="bgs-module   bgs-module-layout-simple">
                                <div className="module-header">
                                    <div className="module-title top_padding_30 bottom_margin_30">
                                        <h2 className="bottom_padding_15">Latest articles</h2></div>
                                </div>
                                <div className="row bgs-posts  " id="setajaxblog">
                                    <div className="block" id={1} />
                                    <LatestTopics />
                                </div>
                            </div> : ''}
                                <div className="module-title top_padding_30 bottom_margin_30">
                                    <h2 id='my-title' className="bottom_padding_15">{searchTitle === '' ? "Editor’s pick" : searchTitle}</h2>
                                </div>
                                {showData ? <div className="row bgs-posts">
                                    <EditorsPick/>
                                </div> : ''}
                            </div>
                            {showData ? <div id="bgs-module-1" className="bgs-module module-type-posts  bgs-module-layout-combo">
                                <div className="module-header">
                                    <div className="module-title top_padding_30 bottom_margin_30">
                                        <h2 className="bottom_padding_15">Hottest Topics</h2></div>
                                </div>
                                <div className="row bgs-posts">
                                    <HottestTopics />
                                </div>
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </section>
            </>
    )
}

export default Blog;