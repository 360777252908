import "./banner.css";
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SettingGif from './../../images/Images/Login/Setting.gif';
import { useNavigate } from 'react-router-dom';



function Bannersection() {

  const [InstantPageUrl,SetInstantPageUrl]=useState('')
  const [InstantPageUrlErr,SetInstantPageUrlErr]=useState('')
  const navigate = useNavigate();
  useEffect(() => {
    const cursor = document.querySelector('.cursor');

    if (cursor) {
      document.addEventListener('mousemove', (e) => {
        cursor.style.top = e.pageY - 10 + 'px';
        cursor.style.left = e.pageX - 10 + 'px';
      });

      const aTagsAndButtons = document.querySelectorAll('a, button, input.url_sbt_btn, input#siteUrl');

      aTagsAndButtons.forEach((element) => {
        element.addEventListener('mouseover', () => {
          cursor.classList.add('hover-effect');
        });

        element.addEventListener('mouseout', () => {
          cursor.classList.remove('hover-effect');
        });
      });

      const inputFields = document.querySelectorAll('input, textarea');

      inputFields.forEach((element) => {
        element.addEventListener('focus', () => {
          cursor.classList.add('hide-effect');
        });

        element.addEventListener('blur', () => {
          cursor.classList.remove('hide-effect');
        });
      });

      document.addEventListener('click', () => {
        cursor.classList.add('expand');

        setTimeout(() => {
          cursor.classList.remove('expand');
        }, 500);
      });
    }
  }, []);


  function isValidUrl(url) {
    // Regular expression for a URL that starts with "http://" or "https://"
    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    // Test the URL against the pattern
    return urlPattern.test(url);
}

  const Runreport=()=>{

    SetInstantPageUrlErr('')
    if(InstantPageUrl==='')
    {
      SetInstantPageUrlErr('Please enter a url here')
    }
    else{
      if(!isValidUrl(InstantPageUrl))
      {
        SetInstantPageUrlErr('Please enter a valid url')
      }
      else{

        localStorage.setItem('site_audit_url',InstantPageUrl)
         navigate('/business-data')
   
      }
    }

   

  }
  return (
    <>
      <div className="bannersection_outer_wrap">
      <div className="bannersection_inner_wrap container">
        <div className="banner_left_wrap">
        <h3 className="site_des">The Ultimate Solution for Comprehensive Website Optimization</h3> 
          {/* <img src={SettingGif} alt="SettingGif" className="SettingGif" /> */}
          <img src="https://cdnneweso.s3.amazonaws.com/Setting.gif" alt="SettingGif" className="SettingGif" />          

          <fieldset className="site_url_outer_wrap">

            <div className="input_field_outer_wrap">
            <input type="url" onChange={(e)=>{SetInstantPageUrl(e.target.value)}} id="siteUrl" name="URL" placeholder="Enter your URL Here With http:// or https://"/>
            <div class="error_field">{InstantPageUrlErr}</div>
            </div>
        
            <span className="url_sbt_btn bsgrowing-fied-outer"><div className="growing-circle"></div><input type="submit" value="Run Free" className="url_sbt_btn" onClick={Runreport}/></span>
          </fieldset>
          
          

        </div>
      </div>
      <div className="cursor"></div>
      </div>
    </>
  );
}

export default Bannersection;