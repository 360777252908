import React, { useState } from 'react';
import './instand.css';
import Plan_gif from '../../../images/Images/Login/success.gif';



function CheckMail() {
    document.body.style.background = 'linear-gradient(271deg, #442c7f, #ba4a9b) 0 0 transparent';
    return (
        <>
        <div className='CheckMail_outer_wrap'>
        <div className='container'>
        <div className='CheckMail_inner_container'>
        <img src={Plan_gif} className="Plan_gif" alt="gif image"/>
        <div className='innr_cnt_wrap'>
        <p className='innr_cnt'>
        Your report is on the way!<span class="innr_cnt_ins">Check your e-mail!</span> 
        </p>
        </div>
        </div>
        </div>
        </div>
        </>
    );
}

export default CheckMail;