import React, { useEffect } from 'react';
import axios from 'axios'

const TokenGenerator = () => {

    const callingTokenGenerator = async() => {
        
       await axios.get(`${process.env.REACT_APP_DOMAIN}/strapi-generators`).then((res) => {console.log(res.data)})
    }

    useEffect(() => {
        callingTokenGenerator()
    }, [])

    return (
        <div>
            Token updated.
        </div>
    )
}

export default TokenGenerator;