import React from "react";
// import ReactDOM from "react-dom";
import LetsgetSection from './letsgetsection.js';
import  FooterSection from './footerSection.js';
import './banner.css';
function CombineFooter() {
  return (
    <>
    <div className="Eso_S_Landing_page">
      <LetsgetSection />
      <FooterSection />
   </div> 

    </>
  );
}

export default CombineFooter;