import React, { useState } from 'react';
import './clientsection.css';
import ImageCarousel from './clientslider';
import PlanMonTabContentSection from './productMonPlansection';
import PlanYearTabContentSection from './productYearPlansection';
import ESO__Lan_ranking from './../../images/Images/Icon/B/ranking.png';
import ESO__Lan_analytics from './../../images/Images/Icon/B/analytics.png';
import ESO__Lan_performance from './../../images/Images/Icon/B/performance.png';
import ESO__Lan_Validator from './../../images/Images/Icon/B/Validator.png';


function ClienSection() {

  const [showMonthly, setShowMonthly] = useState(true);

  const togglePlan = () => {
    setShowMonthly(!showMonthly);
  };

  const images = [
    {ESO__Lan_ranking},
    {ESO__Lan_analytics},
    {ESO__Lan_performance},
    {ESO__Lan_Validator},
    {ESO__Lan_ranking},
    {ESO__Lan_analytics},
    {ESO__Lan_performance},
    {ESO__Lan_Validator},
    
 
    // Add more image URLs as needed
  ];

  return (

    <div className="Our_client_sec_outer_wrap">

       
       <div className='clientscarousel'>
       <h3 className='section_ttl'>OUR CLIENTS</h3>
       <div className='slider_ad_outer_wrap' >
          <ImageCarousel images={images} />
          </div> 
          </div> 
      <div className='choose_your_plan_outer_section_wrap'>
     
      <div className='choose_your_plan_outer_section_cnt_wrap container'>
        <div className='choose_your_plan_inner_section_wrap  container'>
          <div className='choose_sec_cnt_wrap'>
          <h6>Choose Your Plan</h6>
          <p>No Contract and No Surprise Fees</p>
          </div>
          <fieldset class="switch_otr_wrap">
        <label class="plan_switch">
          <input type="checkbox" onClick={togglePlan}/>
          <span class="slider_switch round"></span>
        </label>
        <span className="plan_due_checkbox">
            {showMonthly ? 'Monthly Plan' : 'Yearly Plan'}
          </span>
        </fieldset>
        </div>
        
        <div className='Plan_tb_container'>
        {showMonthly ? <PlanMonTabContentSection /> : <PlanYearTabContentSection />}
      </div>
    </div>
    </div>
  
    </div>
  );
}

export default ClienSection;
