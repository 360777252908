import React, { useEffect } from 'react';

const Disclaimer = () => {

    //Title Change
    useEffect(() => {
        document.title = 'SEO Audit and Reporting Tool | Elite Site Optimizer Inc.';
    }, []);

    return (
        <div id="et-main-area">
            {/*Google Tag Manager (noscript) */}
            {/* Google Tag Manager (noscript) */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/* End Google Tag Manager (noscript) */}
            <div className="terms_pages">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="common_text_inner top_padding_50 bottom_padding_50">
                                <h1 style={{}} className="common_center bottom_padding_30 static_terms" id="Disclaimer_1">Disclaimer</h1>
                                <div>
                                    <div className="wpb_text_column wpb_content_element disclaimer">
                                        <div className="wpb_wrapper">
                                            <p>The Website and the Content is provided “as is”. While eGrove endeavors to provide Content that is correct, accurate and timely, no representations or warranties are made regarding the Website and/or the Content including, without limitation, no representation or warranty that
                                            </p></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="wpb_wrapper disclaimer">
                                        <div className="custom-report-list">
                                            <ul>
                                                <li>The Website or Content will be accurate, complete, current, timely or suitable for any particular purpose,</li>
                                                <li>That the operation of the Website will be uninterrupted or error-free,</li>
                                                <li>That defects or errors in the Website or the Content will be corrected,</li>
                                                <li>That the Website will be free from viruses or harmful components,</li>
                                                <li>That communications to or from the Website will be secure and/or not intercepted. You acknowledge and agree that you are using the Website at your own risk and liability and,</li>
                                                <li>That things happen that are outside our reasonable control, for example, acts of God, adverse weather conditions, strikes and industrial action and failure of our suppliers/ data providers. eGrove will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under Contract that is caused by such events.</li>
                                            </ul>
                                            <p>eGrove may provide links on our Website to the websites of other companies. These links are provided solely as a convenience to you and not as an endorsement by eGrove of such third-party websites. eGrove is not responsible for the content of any third-party website, nor does it make any representation or warranty of any kind regarding any third-party website.</p>
                                            <p className="mb25">The display of third-party trademarks on the Website should not be taken to imply any relationship or license between eGrove and the respective owners of trademark or to imply that eGrove endorses the wares, services or business of the respective owners of trademark.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer;