import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import './blog.css';
import './hfstyle.css';
const Topics = () => {

    let { id } = useParams();
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [token, setToken] = useState()
    const [cardData, setCardData] = useState([])
    const [moreData, setMoreData] = useState([])
    const [search, setSearch] = useState('')
    const [showData, setShowData] = useState(true)
    const [topics, setTopics] = useState(null)

    const [searchData, setSearchData] = useState([])
    const [showSearchData, setShowSearchData] = useState(false)
    const [resultData, setResultData] = useState([])
    const [sliceCountOne, setSliceCountOne] = useState(0)
    const [sliceCountTwo, setSliceCountTwo] = useState(6)
    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [moretopiccount, setMoretopiccount] = useState(0)

      const apiUrlForPage = `${process.env.REACT_APP_DOMAIN}/call-topics-api`
    const searchApi = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${200}`
   

   
    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!',
        countone: countOne,
        counttwo: countTwo,
        id: id
    }

    const callTopicsApi = async () => {
       
        setLoading(true)
        await axios.post(apiUrlForPage, dataParams).then((response) => {  setMoretopiccount(response.data.length); setCardData(response.data) })
            .catch((error) => { console.log(error) })
           
        setLoading(false)
    }
    const loadMore = async () => {
        setLoadingMore(true);
        setCountOne(countOne + 6)
        setCountTwo(countTwo + 6)
        const dataParams = {
            identifier: 'admin',
            password: 'admin@123!',
            countone: countOne + 6,
            counttwo: countTwo + 6,
            id: id
        }
        await axios.post(apiUrlForPage, dataParams).then((response) => { 
            console.log(response.data); 
            setMoretopiccount(response.data.length);
            if(response.data.length > 0)
            setMoreData(response.data) })
            .catch((error) => { console.log(error) })
           
        setLoadingMore(false)
    }
    const callSearchApi = async () => {
        await axios.get(searchApi).then((res) => { setSearchData(res.data) })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        const title = id.replace(/[^a-zA-Z]/g, ' ')
        setTopics(title)
        callTopicsApi()
        callSearchApi()
    }, [id])
    const submit = () => {
         if (search === '') {
            setShowData(true)
            setShowSearchData(false)
            console.log('submit if case')
        } else {
            setShowData(false)
            setShowSearchData(true)
            callSearchApi()
            const showableData = searchData.filter(searchValue => searchValue.title.toLowerCase().includes(search))
            const setLimit = showableData.map((items, index) => { return items })
            setResultData(setLimit)
            console.log('submit else case')
        }

        // await axios.get(searchApi).then((res) => { setSearchData(res.data)})
        if (searchData.length === 0) {
            console.log('empty')
            submit()
        } else {
            // console.log('searchdata', searchData)
            console.log('exit')
        }
    }
    return (
        <div> 
            {/* #main-header */}
            <div id="et-main-area">
                <div id="content" className="bgs-site-content container">
                    <div className="bgs-module   bgs-module-layout-combo">
                        <h1 className="dynamic_title_h1 top_padding_50">Elite Site Optimizer Blog</h1>
                        <div className="module-title top_padding_30 bottom_margin_30">
                            <h2 id='my-title' className="bottom_padding_15">{topics}</h2>
                        </div>
                        <div className="row clearfix">
                            <div className="blog_viewpageall pull-right col-lg-4 col-md-6">
                                <div id="search-3" className="widget bgs-box blg_search bottom_padding_30">
                                </div>
                            </div>
                        </div>
                        
                        {loading ? <i style={{alignItems: 'center',justifyContent: 'center',display: 'flex',fontSize: '35px',marginBottom: '40vw', marginTop: '10vw'}} className="fa fa-spinner" /> : showData ? <div className="row bgs-posts">
                            {/* <EditorsPick /> */}
                            <div className='row bgs-posts'>
                                {cardData.map((items, index) => {
                                    let rawdate = new Date(items?.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article id={items?.id} width="400px">
                                                <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                    <img src={`${process.env.REACT_APP_BLOGS_API}/${items?.featured_image?.image_file?.url}`} alt={items?.featured_image?.alt_text? items?.featured_image?.alt_text:"Blog Images"} />
                                                </HashLink>
                                                <div className="desc_content">
                                                    <h2 id={`card-title-${items.id}`} className="bnt-title h3"><Link to={`/blogs/${items?.url_slug}`} state={{ from: `${items?.url_slug}` }}>{items?.title}</Link></h2>

                                                    <p className="post-meta">  
                                                        <span className="published">{`${month} ${date}, ${year}`}</span> 
                                                        {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                                        | */}
                                                        <div className="bnt-category" style={{marginTop:'7px'}}>
                                                        
                                                            {items?.categories?.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem?.url_slug}`} state={{ from: `${subItem?.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem?.name}</Link> &nbsp;&nbsp;</span> })}
                                                        </div>
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items?.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                                }
                                {moreData?.map((items, index) => {
                                    let rawdate = new Date(items?.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article id={items?.id} width="400px">
                                                <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                    <img src={`${process.env.REACT_APP_BLOGS_API}/${items?.featured_image?.image_file?.formats?.small?.url}`} alt={items?.featured_image?.alt_text? items?.featured_image?.alt_text:"Blog Images"} />
                                                </HashLink>
                                                <div className="desc_content">
                                                    <h2 id={`card-title-${items.id}`} className="bnt-title h3"><Link to={`/blogs/${items?.url_slug}`} state={{ from: `${items?.url_slug}` }}>{items?.title}</Link></h2>

                                                    <p className="post-meta">  |
                                                        <span className="published">{`${month} ${date}, ${year}`}</span> |
                                                        <span className="meta-item meta-views">{items?.views === null ? 0 : items?.views} Views</span>
                                                        |
                                                        <div className="bnt-category">
                                                            {items?.categories?.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem?.url_slug}`} state={{ from: `${subItem?.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem?.name}</Link> &nbsp;&nbsp;</span> })}
                                                        </div>
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items?.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div> : ''}
                        
                    </div>
                </div>
            
                {moretopiccount != 0 ?
                    <nav className="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30" onClick={() => loadMore()}>
                        <a onClick={loadMore} disabled={loading} d="getblogpagnination" className='loadmore-btn'>
                            {loadingMore && <span>Load More</span>}
                            {loadingMore && (<i className="fa fa-spinner fa-spin" />)}
                            {!loadingMore && <span>Load More</span>}
                            {/* {loading ? <i className="fa fa-spinner fa-spin" /> : 'Load More'} */}
                        </a>
                    </nav> : ''} 
            </div>
        </div >
    )
}

export default Topics;