import React , { useState, useEffect } from "react";
import axios from 'axios';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import './style.css'
import logos from './images/logo.png';
import adminimage from "./images/admin.jpg";
import DatatablePage from "./Dashboard.js";
import ErrorDb from "./ErrorDb.js";
import ContactUs from "./ContactForm.js";
import { NavLink } from "react-router-dom";
import asset5 from "./images/asset5.png";
import asset1 from "./images/asset1.png";
import asset2 from "./images/asset2.png";
import asset3 from "./images/asset3.png";
import asset4 from "./images/asset4.png";
import downloadimg from "./images/download.png";
import WhitelabelForm from "./WhitelabelForm";
import SolutionlabelForm from "./SolutionlabelForm";
import { useNavigate } from 'react-router-dom';

const Userspage = () => {
  const navigate = useNavigate();

  let logoutFun = () =>{
    sessionStorage.setItem("email", "");
    return navigate('/adminlogin')  
  };


  // function reply_click() {
  //   console.log("outer111");
  // }
  const toggleCSS = () => {
    console.log("clicked");
      const navbarItems = document.querySelectorAll("a.navbar-item.active img");
      const navInnerItems = document.querySelectorAll("#nav_inner_item");
      const menuTables = document.querySelectorAll(".menu-table");
      const side_wrap_a = document.querySelectorAll("#sidebar-wrapper .side li a"); 
      const side_wrap_icon = document.querySelectorAll("#sidebar-wrapper .side li img");
      const side_wrap_ul = document.querySelectorAll('div#sidebar-wrapper .side');
      const menutablesection = document.querySelector('.tog_menu_table_exit'); 

      side_wrap_a.forEach(item => item.classList.toggle("nav_side_wrap_a"));
      navbarItems.forEach(item => item.classList.toggle("nav_tg_active-img"));
      navInnerItems.forEach(item => item.classList.toggle("nav_tg_hide-span"));
      menuTables.forEach(item => item.classList.toggle("nav_tg_wide-table"));
      side_wrap_icon.forEach(item => item.classList.toggle("nav_side_wrap_icon"));
      side_wrap_ul.forEach(item => item.classList.toggle("icons_nav_side_bar"));
      menutablesection.classList.toggle("tog_menu_table_new");

  };

   return(
      <>
 
  <section className="menu-top">
    <div id="page-content-wrapper">
      <div>
      <a href="" className="logo2">
        <img src={logos} />
      </a>
      </div>
      <div>
      <a href="#menu-toggle"  id="toggleCSS_icon" className="menu-toggle icon">
        <img onClick={toggleCSS} src={downloadimg} alt="menu" />
      </a>
      </div>
      <div className="dropdown">
        <a href="" className="admin">
          <em>Admin </em>
          <span className="user2">
            <img src={adminimage}/>
          </span>
        </a>
        <div className="dropdown-content">
          <a onClick={logoutFun}>
            <img src={asset5}/>
            Logout
          </a>
        </div>
      </div>
    </div>
  </section>
<section className="menu-tab-parent">
  <section className="menu-set">
    <section className="menu-left" id="wrapper">
      <div id="sidebar-wrapper">
        <ul className="side">
          <li>
          <NavLink className="navbar-item" activeClassName="is-active" to="/user-list">
              <img src={asset1} alt="User list" title="User list" />
              <span id="nav_inner_item" className="nav_inner_item nav_user_list">User list</span> 
            </NavLink>
          </li>
          <li>
          <NavLink className="navbar-item" activeClassName="is-active" to="/contact-list">
              <img
                src={asset2}
                alt="Contact Us"
                title="Contact Us"
              />
              <span id="nav_inner_item" className="nav_inner_item nav_Contact_Us">Contact Us</span>
            </NavLink>
          </li>
          <li>
          <NavLink className="navbar-item" activeClassName="is-active" to="/white-label">
              <img
                src={asset3}
                alt="White label forrm"
                title="White label forrm"
              />
              <span id="nav_inner_item" className="nav_inner_item nav_white_labl_frm">White label form</span>
            </NavLink>
          </li>
          <li>
          <NavLink className="navbar-item" activeClassName="is-active" to="/solution-label">
              <img
                src={asset4}
                alt="Solution label form"
                title="Solution label form"
              />
              <span id="nav_inner_item" className="nav_inner_item nav_solution_labl_frm">Solution label form</span>
              </NavLink>
          </li>
          <li>
          <NavLink className="navbar-item" activeClassName="is-active" to="/error-user-list">
              <img
                src={asset2}
                alt="Contact Us"
                title="Contact Us"
              />
              <span id="nav_inner_item" className="nav_inner_item nav_err_pg_user_list">Error Page User List</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  {/* <DatatablePage /> */}
 {/* <ErrorDb/> */}
 {/* <ContactUs /> */}
 {/* <WhitelabelForm /> */}
 {/* */}
  </section>

 
  </section>
</>


   );

}
export default Userspage