import React, { useEffect } from 'react';
import './letsget.css';

function LetsGet() {
  // useEffect(() => {
  //   // Function to handle the scroll effect
  //   const handleScroll = () => {
  //     const section = document.querySelector('.scroll-effect-section');
  //     const sectionOffset = section.getBoundingClientRect().top;
  //     const rotation = -sectionOffset / 20; // Adjust the value for the rotation effect

  //     // Apply the rotation transform to the section
  //     section.style.transform = `rotateX(${rotation}deg)`;

  //     // Rotate back when scrolling upwards
  //     if (rotation > 0) {
  //       section.style.transform = 'rotateX(35deg)';
  //     }
  //   };

  //   // Attach the scroll event listener
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div className="lgs_section_outer_wrap scroll-effect-section">
      <div className="lgs_section_inner_wrap container">
        <h2 className="lgs_wrap_ttl">Let’s Get Started,</h2>
        <h6 className="lgs_wrap_sp_ttl">Contact The Experts And Boost Your SEO Today!</h6>
        <p className="lgs_wrap_desc">
          We have a hotel-related blog so we can share our thoughts and routine in our blog that is updated weekly. We will not spam you, we promise.
        </p>
        <a href="/contact-us/?subject=free-trial-request">Get Your Free Trial</a>
      </div>
      
    </div>
  );
}

export default LetsGet;
