
import React, { useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';

function ImageCarousel({ images }) {
  const [authToken, setAuthToken] = useState()
  useEffect(() => {     


  document.title = 'Website Optimization Services | Website Optimization Tools | ESO';
  callAPIforClientimages()
}, []);
const [clientimage, setClientimage] = useState([])
const responsive = {
  superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
  },
  desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
  },
  tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
  }
};
  const callAPIforClientimages = async () => {

    const dataParams = {
        token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
    }
   const response_data= await axios.get(`${process.env.REACT_APP_BLOGS_API}/our-clients`, dataParams)
     setClientimage(response_data.data) 
console.log(response_data.data);
}
  return (
    <>
                
                <Carousel draggable={true} swipeable={true} infinite={false} responsive={responsive} className="card_row container" autoPlay={ true }
  autoPlaySpeed={12500} infinite={true}>

            
                {clientimage.length > 0 && clientimage.map((item, index) => (
                 <div className='slider_inner_sec'>
                <img src={process.env.REACT_APP_BLOGS_API + item.image.url}
                 alt="Elitesiteoptimizer - custom solution" title="Elitesiteoptimizer - custom solution" style={{ width: 200, height: 100 }}  />
                  </div>
                  
               ))}

            </Carousel>
    </>
  );
}

export default ImageCarousel;
