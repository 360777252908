import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const RecentPosts = () => {

    const kblistapi = `${process.env.REACT_APP_BLOGS_API}/knowledge-base-cms?_sort=id:DESC&_limit=4`
    const [listData, setlistData] = useState([])

    // const callKBapi = async () => {
    //     await axios.get(kblistapi, {
    //         headers: {
    //             Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY3NTE0ODQ5NiwiZXhwIjoxNjc3NzQwNDk2fQ.NW2cdbJDXHoo3FbqlOQTvyRtnZf0gBePqHv9bMo06zw'
    //         }
    //     }).then((res) => {
    //         setlistData(res.data)
    //     })
    // }

    const callKBapi = async () => {
     
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-kb-recent-posts`, dataParams).then((res) => { setlistData(res.data) }).catch((error) => { console.log(error) })
    
      }
      
    useEffect(() => {
        window.scroll(0, 0)
        callKBapi()
    }, [])



    return (
        <div>
            <div id="recent-posts-2" className="kb_widget kb_recent_entries clearfix">
                <h4 className="widgettitle">RECENT POSTS</h4>
                {listData.map((items, index) => {
                    return (
                        <ul>
                            <li>
                                <Link to={`/knowledge-based/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link>
                            </li>
                        </ul>
                    )
                })}
            </div>
        </div>
    )
}

export default RecentPosts;