import React, { useState, useRef, useEffect } from 'react';
import './BlogForm.css';
import ReCAPTCHA from "react-google-recaptcha";
import { HashLink } from 'react-router-hash-link';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

const BlogForm = () => {

    const [isVerified, setIsVerified] = useState(false)
    const Ref = useRef(null);
    const BlogURL = `${process.env.REACT_APP_DOMAIN}/call-blogs-comment-api`;
    const navigate = useNavigate();
    let { id } = useParams();

  // const apiUrl = `/auth/local`;
  const apiUrlForPage = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&url_slug=${id}`

    const [checkBox, setCheckBox] = useState(false)
    const [captcha, setCaptcha] = useState(false)
    const [commentPostId, setCommentPostId] = useState('')
    const [blog, setBlog] = useState('')
    const [commentPostTitle, setCommentPostTitle] = useState('')
    const [pageData, setPageData] = useState([])

    

    const callCheckFunction = () => {
        setCheckBox(!checkBox)
    }

    const checkCaptcha = () => {
        setCaptcha(true)
    }
    
    const [inputValues, setInputValue] = useState({
        comment: "",
        name: "",
        email: "",
        websiteUrl: "",

    });

    const { name, email } = inputValues

    const [validation, setValidation] = useState({
        comment: "",
        name: "",
        email: "",
        checkbox: "",
    });

    

    const checkValidation = () => {
        let errors = { ...validation };

        // Message validation
        if ($('#comment').val() == "") {
            errors.comment = "This field is required.";
        } else {
            errors.comment = "";
        }

        //Name validation
        if ($('#name').val() == "") {
            errors.name = "This field is required.";
        } else if (!(/^[a-zA-Z]+$/g.test($('#name').val()))) {
            errors.name = "Please enter alphabet character only";
        } else {
            errors.name = "";
        }

        //Email validation
        if ($('#email').val() == "") {
            errors.email = "This field is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($('#email').val())) {
            errors.email = "Please enter valid Email";
        } else {
            errors.email = "";
        }

    
        //checkbox validation
        if (checkBox) {
            errors.checkbox = "";
        } else {
           errors.checkbox = "This field is required.";
        }

        //recaptcha validation
        if (captcha){
            errors.recaptcha = "";
        } else {
            errors.recaptcha = "This field is required.";
        }

        return setValidation(errors);
    }

    // const onChange = (value) => {
    //     console.log("Captcha value:", value);
    //     setIsVerified({ isVerified: true })
    // }

    const getPageData = async() => {
        await axios.get(apiUrlForPage, {
            headers: { Authorization: `Bearer ${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`}
        }).then((response) => { 
            console.log("commentid",response.data[0].id)
            console.log("title",response.data[0].title)

            setPageData(response.data) 
                                                            setCommentPostId(response.data[0].id)
                                                            setCommentPostTitle(response.data[0].title)})
    }

    useEffect(() => {
        getPageData();
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
        // checkValidation();
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        Ref.current.focus();
        checkValidation();
         console.log("input-----", inputValues)
         //&& captcha;
        if (name != '' && email != '' && checkBox ) {
            const dataParams = {
                email: inputValues.email,
                url: inputValues.websiteUrl,
                author: inputValues.name,
                comment: inputValues.comment,
                comment_post_ID: commentPostId,
                blog: commentPostTitle,
                comment_post_Title: commentPostTitle,
                cmp_site_url: '',
                first_name: inputValues.name, 
              }
              console.log("Get.dataParams=====>", dataParams)
            await axios.post(BlogURL, dataParams)
                .then((response) => {
                    console.log("Get.Data=====>", response)
                    
                    //redirect logic
                    if (response.data.result == 200) {
                        navigate("/thankyou")
                    }
                })
                .catch(error => { console.log(error) })
        } else {
            console.log("unfilled spaces, thows error")
        }
        
    }


    return (
        <div id="et-main-area">
            {/* <style dangerouslySetInnerHTML={{ __html: "\n    .ohnohoney{\n        opacity: 0;\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 0;\n        width: 0;\n        z-index: -1;\n    }\n" }} /> */}
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/jssocials.css" type="text/css" media="all" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/jssocials-theme-classic.css" type="text/css" media="all" />
            <div id="content" className="blog_viewpageall bgs-site-content container top_margin_50">
                <div className="row">
                    <div className="bgs-content bgs-single-layout-2 col-md-12 col-12">
                        <div id="comments" className="bgs-comments bgs-box bottom_margin_50">
                            <div className="module-header">
                                <div className="module-title bottom_margin_20 top_margin_30">
                                    <h4 className="add-comment">Add comment</h4>
                                </div>
                            </div>
                            <div className="comment-respond">
                                <h3 id="reply-title" className="comment-reply-title"> <small /></h3>
                                <form id="command_form" className="comment-form" noValidate onSubmit={handleSubmit} autoComplete="off">
                                    {/* <input type="hidden" name="_token" defaultValue="UQkuiq9ogdb1baU4Sp0nCPmEdcjMRClVou0K1ZSZ" /> */}
                                    <div className="comment-form-comment bottom_margin_20">
                                        <textarea id="comment" name="comment" placeholder="Comment" value={inputValues.comment}
                                            onChange={(e) => handleChange(e)} ref={Ref} />
                                        <br />
                                        <span className='text-error'>{validation.comment}</span>
                                    </div>
                                    <div className="comment-form-author bottom_margin_20">
                                        <input id="name" name="name" type="text" size={30} maxLength={100} placeholder="Name*"
                                            value={inputValues.name} onChange={(e) => handleChange(e)} />
                                        <br />
                                        <span className='text-error'>{validation.name}</span>
                                    </div>
                                    <div className="comment-form-email bottom_margin_20">
                                        <input id="email" name="email" type="email" size={30} maxLength={100} placeholder="Email*"
                                            value={inputValues.email} onChange={(e) => handleChange(e)} />
                                        <br />
                                        <span className='text-error'>{validation.email}</span>
                                    </div>
                                    <div className="comment-form-url bottom_margin_20">
                                        <input id="websiteUrl" name="websiteUrl" type="url" size={30} maxLength={200} placeholder="Website"
                                            value={inputValues.websiteUrl} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className="comment-form-cookies-consent bottom_margin_20">
                                        <input name="checkbox" type="checkbox" className="checkbox" style={{ width: '15px', marginRight: '10px' }}
                                            value={checkBox} onClick={callCheckFunction} />
                                        I consent to have this website store my submitted information so they can respond to my inquiry(<HashLink to="/terms-conditions/" target="_blank">
                                            Terms &amp; Conditions</HashLink> and <HashLink to="/privacy-policy/" target="_blank">Privacy Policy</HashLink>)
                                        <div id="fm_privacy_policy6-error" />
                                        <span className='text-error'>{validation.checkbox}</span>
                                    </div>

                                    {/* <div className="col-xl-6 col-lg-6 col-md-6">
                                       <center style={{ marginTop: '5px' }}>
                                            {/* <div id="recaptcha" class="g-recaptcha" data-sitekey="6LflX64cAAAAAAiR4Iy9PWSv4kc-j_LrJGEzuRK4"></div> 
                                                <div id="recaptcha" className="g-recaptcha" data-sitekey="6LeLSd4cAAAAAE-POFEiQ9wWyXvSTykPyL-IbDIp" />
                                                <span className="err-hide" id="err-recaptcha" />
                                                <label id="captacha-error" style={{ display: 'none', color: 'red' }}>This field is required.</label>
                                            </center>
                                    </div> */}

                                    {/* <ReCAPTCHA
                                        sitekey="6LdkrQwkAAAAAOGkFoji7cLJxhE9NnOVSZEI_GEf"
                                        value={captcha}
                                        onChange={checkCaptcha}
                                        name="recaptcha"
                                        className='recaptcha-checkbox'
                                    />
                                    <span className='text-error'>{validation.recaptcha}</span> */}

                                    <div className="contact_full bottom_padding_15">
                                        <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
                                        <div className="form-submit bottom_margin_20">
                                            <input type="hidden" name="comment_post_ID" defaultValue={397} id="comment_post_ID" />
                                            <input type="hidden" name="comment_post_Title" defaultValue="7 Ways SEO & PPC Can Work Together For Staffing Agencies in 2022" id="comment_post_Title" />
                                            <input type="hidden" name="blog_url" defaultValue="7-ways-seo-and-ppc-can-work-together-for-staffing-agencies-in-2022" id="comment_post_Title" />
                                            <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} /><br />
                                            <input name="submit" type="submit" id="submit" className="submit" value="Submit Comment" />
                                            {/* <input type="hidden" name="comment_post_ID" value="286" id="comment_post_ID">
                            <input type="hidden" name="comment_post_Title" value="Google Now Penalizes Sites For Improper Link-Building Practices" id="comment_post_Title">
                            <input type="hidden" name="blog_url" value="google-penalized-Link-Building" id="comment_post_Title">
                            <input type="hidden" name="comment_parent" id="comment_parent" value="0">
                            <input name="submit" type="submit" id="submit" class="submit"
                                value="Submit Comment" style="margin-top: 12px;">  */}
                                        </div>
                                    </div>
                                </form>{/* #respond */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BlogForm;