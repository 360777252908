import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ESO_Lan_Layer_x0020_1 from './../../images/Images/Layer_x0020_1.png';
import ESO_Lan_search from './../../images/Images/search.png';
import ESO_Lan_strategies from './../../images/Images/strategies.png';


function Strategiessection() {
  return (
    <>
    <div className="strategies_container_outer_wrap">
     <div className="strategies_container_inner_wrap container">
     <div className="strategies_container_inner_container">
      <div className="strategies_container_inner_cnt_top">
        <h3 className="strategies_ttl">STRATEGIES</h3>
        <h6 className="strategies_sub_ttl"><span className="strategies_sub">Elite Site Optimizer</span> Offers Data-Driven Strategies To Improve Your Site</h6>
        <p className="strategies_desc">Take advantage of this integrated business SEO application data to understand your webpage health, URL accessibility, user experience metrics, content quality, PDF-related accessibility issues, and 508 compliance. Generate reports for actionable insights that will boost your site’s traffic and increase user engagement.</p>
      </div>
      <div className="strategies_container_inner_cnt_btm">
        <div className="strategies_btm_wrap">
          <img src={ESO_Lan_Layer_x0020_1} className="strategies_btm_wrap_icn"/>
          <p className="strategies_btm_wrap_desc">
          For Small and Medium Businesses & Start ups
          </p>
        </div>
        <div className="strategies_btm_wrap">
          <img src={ESO_Lan_search} className="strategies_btm_wrap_icn"/>
          <p className="strategies_btm_wrap_desc">
          For SEO Professionals & Digital Marketing Agencies
          </p>
        </div>
      </div>
      </div>
      <div className="strategies_container_inner_wrap_rgt">
        <img src={ESO_Lan_strategies} className="strategies_lft_warp" />
        
        <div className="strategies_lft_warp_reviews">
        <p className="strategies_lft_warp_des"> Our Happy Customer</p>
        {/* <div className="reviewer_outr_wrap">
        <span className="reviewer_icon1"><img src="/Images/smiling-young-brunette-caucasian-girl-looks-isolated-purple-wall-min.jpg"/></span>
        <span className="reviewer_icon2"><img src="/Images/handsome-man-with-glasses-min.jpg"/></span>
        <span className="reviewer_icon3"><img src="/Images/fashionable-young-woman-standing-front-blue-backdrop-min.jpg"/></span>
        </div> */}
        <div className='rvr_outer_container'>
        <FontAwesomeIcon icon={faStar} />
        <span className="reviewer_rate">4.9 <span className="reviewer_count">(2568 reviews)</span></span>
        </div>
        </div>
    </div>
    </div>
 
</div>

    </>
  );
}

export default Strategiessection;