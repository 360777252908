import React, { useEffect } from 'react';

const TermsConditions = () => {

    //Title Change
    useEffect(() => {
        document.title = 'Terms & Conditions | Elite Site Optimizer Inc.';
    }, []);

    return (
        <div id="et-main-area">
            {/*Google Tag Manager (noscript) */}
            {/* Google Tag Manager (noscript) */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/* End Google Tag Manager (noscript) */}
            <div className="terms_pages">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="common_text_inner top_padding_50 bottom_padding_50">
                                <h1 style={{}} className="common_center bottom_padding_30 static_terms" id="term-1">Terms &amp; Conditions</h1>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-2">Acceptance:</h2>
                                <div>
                                    <p>This Terms of Use Agreement (“Agreement”) is a legal agreement between you and eGrove Systems Corporation. eGrove Systems Corporation (“eGrove”) is the owner and operator of this Website (the “Website”) and Elite Site Optimizer (the “ESO” Software). It states the terms and conditions under which you may access and use the Website. By accessing and using the Website, you are indicating your acceptance to be bound by the terms and conditions of this Agreement. If you do not accept these terms and conditions, you must not access or use the Website. eGrove may revise this Agreement at any time by updating this post. Use of the Website / software after such changes are posted will signify your acceptance of these revised terms. You should visit this page periodically to review this Agreement. Elite Site Optimizer /eGrove reserves the right to display your business name/ customer name/ logo/ website link of subscribers (Paid or Trial users)/ customers, on its websites or portfolios or marketing materials.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-3">Condition of Use:</h2>
                                <div>
                                    <p>Subject to the terms and conditions of this Agreement, you are hereby granted a limited, non-transferable and non-exclusive license to access, view and use the Website for your personal and/ or commercial gain and are granted the right to download, store and print single copies of items comprising the Content for your personal and/ or commercial use, provided that you maintain all copyright and other notices contained in such content. Please note that eGrove is entitled to refuse to accept any order and this will be notified via email.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-4">Third-party Products And Services/Links:</h2>
                                <div>
                                    <p>eGrove may provide links on our Website to websites of other companies. These links are provided solely as a convenience to you and not as an endorsement by eGrove of third-party websites. eGrove is not responsible for the content of any third-party website, nor does it make any representation or warranty of any kind regarding any third-party website</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="terms-5">Price Changes:</h2>
                                <div>
                                    <p>eGrove may vary Subscription Fees, the composition of features included within a Package, our Package Limits and/or the Overage Charges applicable to any or all Packages by notifying you at any time. Such notice will deemed to have been given by sending you an email to the email address listed on your Account page, and/or by notifying you within the Software. Such price changes will take effect at the start of the next Billing Period.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-6">Payment And Fees:</h2>
                                <div>
                                    <p>eGrove will charge your credit card at the start of each Billing Period for any Subscription Fees due and/or for Overage Charges due for the Billing Period just ended. eGrove reserves the right to refuse requests to pay by bank transfer or other means. In the event that eGrove agrees to accept payment by bank transfer or other means, then cleared funds must be received within 7 days of opening your Account or access to the Software may be temporarily suspended. Thereafter cleared funds must be received within 7 days of invoice at the start of each Subscription Period or access to the Software may be temporarily suspended. If your credit card payment fails or you fail to make any payment by the due date then, in addition to any other right or remedy available to us, we will be entitled, but not obliged, at our sole discretion, to (i) immediately suspend your access to the Software, (ii) stop providing services to the sites on your Account if payments are more than 7 days late, (iii) terminate your account and delete all data held on your Account if payments are more than 30 days late and (iv) exercise our legal right to claim interest under any applicable law. All payments are non-refundable. No refunds or credits will be made for partial months of service. All fees are stated exclusive of all taxes, levies or duties imposed by all relevant tax authorities, and you shall be responsible for payment of all relevant taxes, levies and duties where applicable. You agree to pay for any such taxes that might be applicable to your use of the Software. Overage Charges (if any) will be billed in arrears at the end of each Billing Period. If you cancel your Account for any reason, you will be liable for any Overage Charges incurred during the current Billing Period up to the date of cancellation.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-7">Intellectual Property And Content:</h2>
                                <div>
                                    <p>All materials displayed or otherwise accessible through this Website (collectively, “Content”) are protected by copyright law, and are owned by eGrove or the party accredited as the provider of the Content. Except as granted in the limited license herein, any use of the Content, including modification, transmission, presentation, distribution, republication, or other exploitation of the Website or of its Content, whether in whole or in part, is prohibited without the express prior written consent of eGrove. You acknowledge and agree that eGrove and/or our licensors own all intellectual property rights of the Website. Except as expressly stated herein, this agreement does not grant you any rights to, or in, patents, copyrights, database rights, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licenses in respect of the Software. eGrove confirms that we have all the rights in relation to the Website that are necessary to grant all the rights we purport to grant under, and in accordance with, the terms of this agreement.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-8">Indemnification:</h2>
                                <div>
                                    <p>In no event shall eGrove or our subsidiaries, affiliates, officers, partners and employees or agents be liable for damages of any kind including, without limitation, any direct, special, indirect, punitive, incidental or consequential damages including, without limitation, any loss or damages in the nature of or relating to lost business, lost savings, lost data and/or lost profits arising from your use of, reliance upon, or inability to use the Website or the Content, regardless of the cause and whether arising in contract, tort (including negligence), or otherwise. This includes reasonable attorneys’ fees, made by any third-party due to or arising out of your use of the Software, use of your Account by any third-party, the violation of the terms of use by you, or the infringement by you, or any third-party using your Account, of any intellectual property or other right of any person or entity. The foregoing limitation shall apply even if eGrove knew of or ought to have known of the possibility of such damages.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-9">Non-Disparagement:</h2>
                                <div>
                                    <p>Customers and/or website visitors agree that any issue with the tools or services offered through this site should be communicated to eGrove for a possible solution. Customers or website visitors should not disparage or comment negatively about eGrove, its officers and management, and/or current or former employees.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-10">Disclaimer Of Warranties:</h2>
                                <div>
                                    <p>The Website and the Content is provided “as is”. While eGrove endeavors to provide Content that is correct, accurate and timely, no representations or warranties are made regarding the Website and/or the Content including, without limitation, no representation or warranty that (i) the Website or Content will be accurate, complete, current, timely or suitable for any particular purpose, (ii) that the operation of the Website will be uninterrupted or error-free, (iii) that defects or errors in the Website or the Content will be corrected, (iv) that the Website will be free from viruses or harmful components, and (v) that communications to or from the Website will be secure and/or not intercepted. You acknowledge and agree that you are using the Website at your own risk and liability (vi) that things happen that are outside our reasonable control, for example, acts of God, adverse weather conditions, strikes and industrial action and failure of our suppliers/ data providers. eGrove will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under Contract that is caused by such events.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-11">Security:</h2>
                                <div>
                                    <p>Any information sent or received over the Internet is generally not secure. eGrove cannot guarantee security or confidentiality of any communication to or from the Website.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-12">Copyrights &amp; Trademarks:</h2>
                                <div>
                                    <p>Elite Site Optimizer is the property of eGrove Systems Corporation. Other names, words, titles, phrases, logos, designs, graphics, icons and trademarks displayed on the Website may constitute registered or unregistered trademarks of eGrove or third parties. The display of third-party trademarks on the Website should not be taken to imply any relationship or license between eGrove and the respective owners of trademark or to imply that eGrove endorses the wares, services or business of the respective owners of trademark.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-13">Governing Law And Jurisdiction:</h2>
                                <div>
                                    <p>By accessing or using the Website, you agree that all matters relating to your access to, or use of, the Website and its Content shall be governed by the laws of the State of New Jersey and the Laws of USA, without regard to conflict of laws principles. You agree and hereby submit to the non-exclusive jurisdiction of the courts of the State of New Jersey with respect to all matters relating to your access to and use of the Website.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="term-14">Termination:</h2>
                                <div>
                                    <p>eGrove may, at its sole discretion, cancel or terminate your right to use the Website, or any part of the Website, including any trial plans, at any time without notice, for any reason. In the event of termination, you are no longer authorized to access the Website or the part of the website affected by such cancellation or termination. eGrove shall not be liable to any party for such termination.</p>
                                </div>
                                <h2 style={{ textAlign: 'left' }} className="static_terms" id="General">General:</h2>
                                <div>
                                    <p>Except for any agreement in respect of Content, this is the entire agreement between you and eGrove relating to your access and use of the Website and the Content herein. If any term or provision of this Agreement is held by a court of competent jurisdiction to be invalid, it shall be severed and the remaining provisions shall remain in full force without being invalidated in any way. If we fail to exercise or delay in exercising a right or remedy provided by these Terms or by the Contract or by law, it does not mean we give up those or any other rights or remedies. We reserve the right to exercise those rights or remedies in the future. eGrove may perform any of our obligations or exercise any of our rights ourselves or through any third-party provider.</p>
                                    <p className="mb25"><i>*The domain name should not be a porn site or political site or site that contains offensive language in it as Elite Site Optimizer will not analyze these kinds of websites.</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsConditions;