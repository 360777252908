import React, { useState } from "react";
// import { emailValidator } from './regex';
import './validationForm.css';
import { useNavigate } from 'react-router-dom';

const ValidationForm = () => {

    const [error, setError] = useState('')
    const [error2, setError2] = useState('')
    const [password, setPassword] = useState('')
    const userEmail = localStorage.getItem('userEmail');
    
    const userIn = password;
    const otpGen = localStorage.getItem('otpGen')
    // console.log(otpGen);
    
    const navigate = useNavigate();

    const handleChange = (e) => {
        setPassword(e.target.value);
    }

    const submit = (e) => {
        e.preventDefault();
        setError("");

        if ((document.getElementById("password").value) === ''){
                setError2("Please enter 5-digit access code!")
            } else if(otpGen == userIn){
                navigate('/trial-report');
            } else {
                setError2('Invalid OTP')
            }
    }
 
    return (
        <div className="et-main-area">
            <div className="cta_bg_wrap_form_two">
                <form action="action.php">   {/* action="action.php" */}
                    <div className='validate-email-field-two'>
                        
                        <h2 >Please enter OTP <br />
                            sent to your Mail Id</h2>
                    
                        {/* <p>Email Address</p> */}
                       
                        {/* <input type='email' name="email" id="email" placeholder="Sample@businessmail.com" value={userEmail} disabled='disabled' /><br/>
                        <span className='error-message-1' style={{ color: "rgb(255, 57, 57)" }}>{error}</span> */}
                       {/* <h5>&nbsp;</h5>*/}
                        <input type='password' id='password' placeholder="5-digit access code" onChange={handleChange}/>
                        <br /><span className='error-message-2'>{error2}</span>
                       {/* <br /> */}
                        {/* < <h5>&nbsp;</h5>
                        <h5>&nbsp;</h5>*/}
                        {/* <a href='/instant-audit-features/response-notification/'>Validate Email Id</a> */}
                        <button onClick={submit} type='email' className="btn btn-warning">Run report</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ValidationForm;