import React, { useEffect } from 'react';
import './access-validation.css';
import ValidationForm from '../ValidationForm/ValidationForm';
import InstantAuditTheme from './../../../images/astro/instant-audit-theme.png';
import RocketLeft from './../../../images/astro/rocket-left.png';

const AccessValidation = () => {
    useEffect(() => {
        document.title = 'Instant Audit Features | SEO Automation Tool';
    }, []);

    // const [message, setMessage] = useState('');

    // const handleChange = (event) => {
    //     setMessage(event.target.value);
    //     // var userDomain = value;
    //     if (event.target.value === 'localhost') {
    //         console.log(true);
    //     } else {
    //         console.log(false);
    //     }
    // };

    // let localDomain = document.domain;

    // let localDomain = document.domain;
    // let userDomain = 'localhost';
    // let result = localDomain.localeCompare(userDomain);
    // console.log(result);


    return (
        <div className='et-main-area'>
            <div className='cta_bg_wrap_ins_two'>
                <div className='instant_container_two'></div>
                <div className='instant-audit-container_two instant-audit-container_one_of_two'>
                    <img src={InstantAuditTheme} />
                    <img className='rocket-left-2' src={RocketLeft}/>
                    <div className='email-validator-form-two'>
                        <ValidationForm />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccessValidation;