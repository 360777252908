import React, { useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import './Thankyou.css';
// import imageUrl from '../../images/eso/images/thankyou-banner.jpg';
import confirmIcon from '../../images/eso/images/confirm-icon.png';
import bg_3 from '../../images/eso/home_image/bg_3.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';

const Thankyou = () => {
  useEffect(() => {
    document.title = 'Thankyou | Elite Site Optimizer';
  }, []);

  // const imageUrl = '../../images/eso/images/thankyou-banner.jpg'; 

  return (
    <div>
      <div id="page-container" className="et-animated-content">
      </div>
      {/* #main-header */}
      <div id="et-main-area">
        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.thankyou_page .starts-here{\n\t\ttext-shadow: 0 6px 16px rgb(0 0 0 / 79%);\n\t\t    font-size: 51px;\n\t\t    padding: 30px 0px;\n\t\t    margin: auto; \n\t}\n\t@media  only screen and (min-width: 850px){\n\t\t.thankyou_page .starts-here{\n\t\t\twidth: 800px;\n\t\t} \n\t}\n@media  only screen and (min-width: 500px) and (max-width: 767px){\n\t.thankyou_page .starts-here{\n\t\tfont-size: 34px;\n\t}\n}\n" }} />
        {/* <div className="thankyou_page clearfix" style={{ backgroundImage: `url(${confirmIcon})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 common_center top_padding_50 bottom_padding_50">
                <h2 className="text-white starts-here">
                  Your <span style={{ color: '#ffae1f' }}>Website Optimization</span>
                  Starts Here!
                </h2>
              </div>
            </div>
          </div>
        </div> */}
        <img src={confirmIcon} className="confirm-icon" />
        <div className="common_text_inner confirm-msg">
          <div className="container">
            <div className="col-lg-12 thank_message top_padding_50 bottom_padding_50 top_margin_20 bottom_margin_30">
              <p style={{marginTop: "-35px"}}>Thank You For Contacting Us!</p>
              <p>We have received your message. Our support team will contact you within two business days.</p>
              {/* <p>Elite Site Optimizer Team</p> */}
            </div>
          </div>
        </div>
        <div className="cta_bg_wrap">
          <div className="cta_bg_img_left_outer_wrap">
            <img src={bg_4} alt="CTA_bg" />
          </div>
          <div className="cta_bg_img_right_outer_wrap">
            <img src={bg_3} alt="CTA_bg" />
          </div>
          <div className="container">
            <div className="cta_content_outer_wrap">
              <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
              {/*  <a href="#">Request Free Trial</a> */}
            </div>
          </div>
        </div>
      </div>
      {/* #et-main-area */}
      {/* <div className="main-addon hide-overlay">
        <div className="overlay">
          <div className="overlay-content">
            <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
            <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`}  />
          </div>
        </div>
        <div className="buttons-container">
          <button className="button supportBTN"><i className="fa fa-search" />Support</button>
        </div>
      </div> */}
       {/*Start of Zendesk Chat Script*/}
      {/*End of Zendesk Chat Script*/}{/*'}'*/}
      {/* <div class="cta_bg_wrap">
<div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
<div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
<div class="container">
    <div class="cta_content_outer_wrap">
        <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
</div>
</div> */}
      {/* #page-container */}
    </div>
  );
}

export default Thankyou;