import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
// import classNames from 'classnames';
import './performance.css';
import Form from './../../Form/Form'
import { HashLink } from 'react-router-hash-link';
import GroupThree from './../../../../images/str/uploads/GroupThree.jpeg'
import seo_ec4951b86a from './../../../../images/str/uploads/seo_ec4951b86a.png'
import Web_page_performance_banner from '../../../../images/str/uploads/Web_page_performance_banner.jpeg';

const PerformanceBasedSEO = () => {
    useEffect(() => {
        document.title = 'Performance Based SEO | SEO Services | Pay for Performance';
    }, []);

    const [isOpen, setIsOpen] = useState(true);

    const onClickSelector = () => {
        setIsOpen(!isOpen);
    }
    
    return (
        <div>
            
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/plugin/Country-Flags-Responsive-CSS-Sprite-master/css/flags.css" />
                <style dangerouslySetInnerHTML={{ __html: "\n.error{\n\torder: 2;\n    margin:0 auto;\n}\n#captacha-error{\n    color: red!important;\n}\n.ohnohoney{\n        opacity: 0;\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 0;\n        width: 0;\n        z-index: -1;\n    }\n\n" }} />
                {/* <div className="performance_banner common_banner_center1 overlay" style={{ backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row common_left">
                            <div className="col-12">
                                <div className="serv_pb_slide_content">
                                    <h2>Services</h2>
                                    <h1 className="banner_title">Performance based SEO Services</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                   <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                    <div className="container">
                        <div className="page_title_img_wrap">
                            <img src={Web_page_performance_banner} alt="webpage_performance" />
                        </div>
                        <h2>Services</h2>
                        <h1>Performance based SEO Services</h1>
                    </div>
                </div>
                <div className="performance_based_sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="common_text_inner top_padding_50">
                                    <p><span style={{ fontWeight: 400 }}>Performance-based SEO services target </span><HashLink to="/features/traffic-analytics/" elementId='root' target="_blank" rel="noopener">missed opportunities</HashLink> <span style={{ fontWeight: 400 }}>of e-commerce websites in terms of traffic, ranking, sales, and lead generation. The SEO experts from the Elite Site Optimizer team will create a tailor-made SEO strategy through a superior understanding of your business goals. We perform a comprehensive gap analysis of your current SEO process and subsequently build and implement end-to-end SEO processes from start to finish.</span></p>
                                </div>
                                <div className="common_image_wrap" style={{ textAlign: 'center' }}><img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} title="Performance based SEO Services" src={GroupThree} alt="Performance-based SEO Services" /></div>
                                <div className="row performance_based_sect1 top_padding_30">
                                    <div className="col-lg-7 col-md-12  col-sm-12 col-12">
                                        <div className="common_text_inner top_padding_50 top_margin_50">
                                            <h2 style={{ textAlign: 'left' }}>What is a performance-based SEO service?</h2>
                                            <p><span style={{ fontWeight: 400 }}>Performance-based SEO services target the missed opportunities of e-commerce websites in terms of traffic, ranking, sales, and lead generation. ESO’s team will start from competitor analysis and implement SEO strategies to improve website performance.</span></p>
                                        </div>
                                    </div>
                                    {/* .et_pb_column */}
                                    <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                        <div className="common_image_wrap"><img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} title="Pay for Performance" src={seo_ec4951b86a} alt="Pay for Performance" /></div>
                                    </div>
                                </div>
                                <div className="performance_based_sect3 top_padding_30 bottom_padding_50">
                                    <div className="common_text_inner">
                                        <h2 style={{ textAlign: 'left' }}>Benefits of our Performance-based SEO plans</h2>
                                        <ul className="left_padding_15">
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Pay when you see incremental revenue, making our service low-risk&nbsp;</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>All our plans are based on cost-to-benefit ratio.</span></li>
                                            <li>Our service is flexible in nature.</li>
                                            <li>In our performance-based <HashLink to="/services/digital-marketing-services/" elementId='root' target="_blank" rel="noopener">SEO services</HashLink> <span style={{ fontWeight: 400 }}>we build all our strategies with a milestone that can be tested within a short span of time. If our plan fails, we are quickly up on our feet implementing plan B.</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We ensure free flow of communication by involving you or a customer manager from your end in every stage of building a robust SEO process</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>This methodology is easily scalable and can function in parallel for all your other websites if need be, although each of them needs a unique planning structure.</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>A team of highly experienced professionals to achieve the targeted goals.</span></li>
                                        </ul>
                                        <h2 className="top_padding_30">Pricing</h2>
                                        <p>Our pricing starts from 5% of the incremental revenue. It is completely based on the “<strong>No Revenue, No Pay</strong>” method.</p>
                                        <h2 className="top_padding_15">Why choose Elite Site <strong>Optimizer?</strong></h2>
                                        <p><span style={{ fontWeight: 400 }}>Elite Site Optimizer helps customers across the globe to get more revenue with our Performance-Based SEO service. Our impeccably-designed, performance-based SEO methodology makes us work harder and smarter for you and enables us to reach the desired outcome in a very short period of time. We have a great team of professionals and experts in this domain to get the best results with our Performance-Based SEO Services, and our promise is that we get great results every single time. You’ll notice the outcome before you spend any money from your pocket.</span></p>
                                        <p><span style={{ fontWeight: 400 }}>We are also the developers of a unique SEO Automation tool that produces instant reports on On-page issues, Broken Links, Keyword ranking,</span>&nbsp;<HashLink to="/features/webpage-seo-analysis/" elementId='root' target="_blank" rel="noopener">Mobile Keyword Ranking</HashLink>, <span style={{ fontWeight: 400 }}>Missed Opportunities, and much more. You will also get Branded PDF reports weekly, which you can customize according to your convenience. Check out our</span> <HashLink to="/plans-and-pricing/" target="_blank" elementId='root' rel="noopener">Pricing Plans</HashLink> <span style={{ fontWeight: 400 }}>for the Elite Site Optimizer SEO Automation Tool to enhance your ROI.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* #et-main-area */}
                {/* <div className="main-addon hide-overlay">
                    <div className="overlay">
                        <div className="overlay-content">
                            <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                            <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`}  />
                        </div>
                    </div>
                    <div className="buttons-container">
                        <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                    </div>
                </div> */}
                 {/*Start of Zendesk Chat Script*/}
                {/*End of Zendesk Chat Script*/}{/*'}'*/}
                {/* <div class="cta_bg_wrap">
  <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
  <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
  <div class="container">
      <div class="cta_content_outer_wrap">
          <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
  </div>
  </div> */}
                <div id="cookie-notice" className="cn_bottom" style={{ color: 'rgb(255, 255, 255)', visibility: 'visible', backgroundColor: 'rgb(0, 0, 0)' }}>
                    <div className="cookie_notice_container"> <span className="cn_notice_text">We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span><a className="cn_set_cookie btn-primary btn-sm left_margin_15">I Agree</a><HashLink to="/privacy-policy/" elementId='root' target="_blank" className="left_margin_15 btn-primary btn-sm cn_more_info">Privacy policy</HashLink> </div>
                </div> {/* #main-footer */}
                {/* #page-container */}
            </div>
            <Form />
        </div>
        
    )
}

export default PerformanceBasedSEO;
