import React, { useState } from 'react';
import './instand.css';
import OTP_valid_gif from '../../../images/Images/Login/otp_lock.gif';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

function OTPValidation() {


  const urlParams = new URLSearchParams(window.location.search);
  const email = localStorage.getItem('site_audit_email')
  const navigate = useNavigate();
  const [loading,setloading]=useState(false)
  const [errormsg,Seterrormsg]=useState('')



  document.body.style.background = 'linear-gradient(271deg, #442c7f, #ba4a9b) 0 0 transparent';
    const [inputValue, setInputValue] = useState('');

    // Function to handle changes in the input field
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
  
    // Function to handle form submission
    const handleSubmit = async(event) => {
      event.preventDefault();
      // Do something with the input value, e.g., send it to an API or perform some action
      Seterrormsg('')
      if(inputValue!=='')
      {
      
        
        setloading(true)

        try{

        
         const Response= await axios.post(''+process.env.REACT_APP_API_V3_URL+'/landing_page/new_free_instant_report_verify_otp/', {
            otp: inputValue,
            email: email,
           
        })

        if(Response.data)
        {
          setloading(false)
          navigate('/instand-mail-response')
        }


        }catch(err)
        {

          Seterrormsg(err.response.data.error)
          setloading(false)
        }
    



      }
      else{
        Seterrormsg('Please enter a OTP')
      }
  
    };
    return (
        <>
        <div className='OTPValidation_outer_wrap'>
        <div className='container'>
        <div className='img_gif_wrap'><img src={OTP_valid_gif} alt="gif image"/></div>
        <div className='OTPValidation_inner_container'>
        <p className='container_des_wrap'>Please enter OTP <span className='container_des_wrap_inr'>sent to your Mail Id</span></p>
        <div className='innr_cnt_wrap'>
        <form onSubmit={handleSubmit} className='otp_valid_frm'>
        <input type="text" value={inputValue} onChange={handleInputChange} placeholder='6-digit access code' className='otp_field'/>
        <div style={{color:'#ffae1f'}}>{errormsg}</div>
      <button type="submit" className='otp_bt_smt'>Run Report
      

      {
        loading===true ?       <Oval
        height={20}
        width={20}
        color="#fff"
        wrapperStyle={{}}
        wrapperClass="otp_bt_smt_loading"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      
      /> : null
      }
      

      
      </button>
       </form>
        </div>
        </div>
        </div>
        </div>
        </>
    );
}

export default OTPValidation;