import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Categories from './Categories';
import RecentPosts from './RecentPosts';
import './kb.css';

const DynamicKb = () => {

    let { id } = useParams()
    

    const showMoreApi = `https://strapi.egrovetech.com/kb-lists?url_slug=${id}`
    const [pageData, setPageData] = useState([])
    const [sValue, setSValue] = useState()


    const navigate = useNavigate();
    const handleChange = (e) => {
        setSValue(e.target.value);
        localStorage.setItem('sValue', sValue)
    }
    const submit = () =>{
        navigate('/kb/search')
    }

    // const callSlugApi = async () => {
    //     await axios.get(showMoreApi, {
    //         headers: {
    //             Authorization: `Bearer ${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`
    //         }
    //     }).then((res) => { setPageData(res.data) })
    //     //   .then((res) => { ((res.data))})
    //         .catch((error) => { console.log(error) })
    // }

    const callSlugApi = async () => {
 
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-kb-showmore`, dataParams).then((res) => { setPageData(res.data) }).catch((error) => { console.log(error) })
    
      }

    const clicked = () => {
        console.log('clicked')
    }
    const onFormsubmit = e =>{
        if (e.keyCode === 13 || e.key === 'Enter' ) {
        navigate('/kb/search')
        }
    }


    // console.log(pageData)

    useEffect(() => {
        window.scroll(0, 0)
        callSlugApi()
    }, [id])

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link href="https://www.elitesiteoptimizer.com/public/assets/eso/images/eso-fav_icon.png" rel="icon" type="image/png" sizes="32x32" />
            <title>Link Analysis | KnowledgeBase | Elite Site Optimizer</title>
            <link rel="canonical" href="https://www.elitesiteoptimizer.com/kb/link-analysis/" />
            <meta name="description" content="Link checker sections help the user in analyzing all the links on their website and help in enhancing the website's link building activities." />
            <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            {/* facebook */}
            {/* twitter */}
            {/* linkedin */}
            {/* PAGE custom css   */}
            {/* PAGE custom jS   */}
            {/* PAGE Tracking code   */}
            {/*Google Tag Manager */}
            {/* Google Tag Manager */}
            {/* End Google Tag Manager */}
            {/*Google Analytics Code */}
            {/* Global site tag (gtag.js) - Google Analytics */}
            {/*Facebook Pixel Code */}
            {/* Facebook Pixel Code */}
            <noscript>
                &lt;img height="1" width="1"
                src="https://www.facebook.com/tr?id=553406781904038&amp;ev=PageView
                &amp;noscript=1" alt="Facebook"/&gt;
            </noscript>
            {/* End Facebook Pixel Code */}
            {/* custom_css and Js  */}
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/eso/css/hfstyle.css" />

            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                <div id="main-content" className="kb_listing kb_knowledge_page kb_page">
                    <div className="container">
                        <div className="pakb-header top_margin_50 col-md-7 left_padding_0">
                            <form role="search" id="kbsearchform">
                                <div className="pakb-search">
                                    <input type="hidden" name="_token" defaultValue="NY9GcHC4zPLnv4nn4CgCvi1qVxrVRjdtLXPZYb4c" />							
                                    <input type="text" name="s" placeholder="Search" id="tags" className="autosuggest" autoComplete="off" />
                                    <span class="fa fa-search searchbutton"><input onClick={submit} id="searchsubmit" defaultValue="Search" /></span>
                                    <input type="hidden" name="post_type" defaultValue="knowledgebase" />
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            {pageData.map((items, index) => {
                                
                                 
                                return (
                                    <div className="common_text_inner top_margin_50 col-md-8 col-12">
                                       
                                        <h1>{items.name}</h1>
                                        
                                        {(items.description_amp !== null) ? (parse(items.description_amp)) : ""}
                                        
                                        
                                        <ul className="pakb-list left_padding_15">
                                            {items.kb_cms.map((subItems, subIndex) => {
                                                return (
                                                    <li><i className="si-file-text2" /> <Link to={`/knowledge-based/${subItems.url_slug}`} state={{ from: `${items.url_slug}` }} onClick={clicked}>{subItems.title}</Link></li>
                                                )
                                            })}
                                            
                                        </ul>
                                    </div>
                                )
                            })}
                            <div id="sidebar" className="col-md-4 col-12 bottom_padding_30">
                                <div id="search-3" className="kb_widget kb_search">
                                    <form role="search" method="post" id="searchform" className="searchform" action="https://www.elitesiteoptimizer.com/kb/search/">
                                        <div>
                                            <input type="hidden" name="_token" defaultValue="NY9GcHC4zPLnv4nn4CgCvi1qVxrVRjdtLXPZYb4c" />										<label className="screen-reader-text" htmlFor="s">Search for:</label>
                                            <input value={sValue} onChange={handleChange} type="text" name="s" placeholder="Search" id="tags" className="autosuggest"
                                           onKeyPress={onFormsubmit} />
                                            <input type="submit" id="searchsubmit" defaultValue="Search" />
                                        </div>
                                    </form>
                                </div>
                                <Categories />
                                {/* end .kb_widget */}
                                <RecentPosts />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> */}
            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}
            
            
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default DynamicKb;