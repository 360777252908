import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


function PlanMonTabContentSection() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabData = [
    {
      title: (
        <div className='plan_tab_ttl_inner_section_wrap'>
          <span class="checkmark_outer"><FontAwesomeIcon className="checkmark_outer_tab_icon" icon={faCheck} /><span className='checkmark_outer_tab_ttl'>Base Plan</span>
           </span>
          <span class="plan_dis">Save 10%</span>
          <a href="#0">Learn More</a>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container">
   
          <div className='plan_tab_cnt_wrap'>
            <div className='plan_tab_cnt_head_wrap'>
              <span className='plan_tab_lft_ttl'>Details</span>
              <span className='plan_tab_rgt_ttl'>Base Plan</span>
            </div>
            <div className='plan_tab_cnt_body_wrap'>
            <ul className='plan_tab_cnt_body_ul'>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">All Limited Links</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Own Analytic Platform</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
            </ul>
            </div>
          </div>
  
      
             <a href="#0" className='plan_tab_inr_btn'>Check Now</a>
          </div>


      ),
    },
    {
      title: (
        <div className='plan_tab_ttl_inner_section_wrap'>
          <span class="checkmark_outer"><FontAwesomeIcon className="checkmark_outer_tab_icon" icon={faCheck} /><span className='checkmark_outer_tab_ttl'>Fuse Plan</span>
           </span>
          <span class="plan_dis">Save 10%</span>
          <a href="#0">Learn More</a>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container">
   
          <div className='plan_tab_cnt_wrap'>
            <div className='plan_tab_cnt_head_wrap'>
              <span className='plan_tab_lft_ttl'>Details</span>
              <span className='plan_tab_rgt_ttl'>Fuse Plan</span>
            </div>
            <div className='plan_tab_cnt_body_wrap'>
            <ul className='plan_tab_cnt_body_ul'>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">All Limited Links</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Own Analytic Platform</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
            </ul>
            </div>
          </div>
  
      
             <a href="#0" className='plan_tab_inr_btn'>Check Now</a>
          </div>


      ),
    },
    {
      title: (
        <div className='plan_tab_ttl_inner_section_wrap'>
          <span class="checkmark_outer"><FontAwesomeIcon className="checkmark_outer_tab_icon" icon={faCheck} /><span className='checkmark_outer_tab_ttl'>Extro Plan</span>
           </span>
          <span class="plan_dis">Save 10%</span>
          <a href="#0">Learn More</a>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container">
   
          <div className='plan_tab_cnt_wrap'>
            <div className='plan_tab_cnt_head_wrap'>
              <span className='plan_tab_lft_ttl'>Details</span>
              <span className='plan_tab_rgt_ttl'>Extro Plan</span>
            </div>
            <div className='plan_tab_cnt_body_wrap'>
            <ul className='plan_tab_cnt_body_ul'>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">All Limited Links</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Own Analytic Platform</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
            </ul>
            </div>
          </div>
  
      
             <a href="#0" className='plan_tab_inr_btn'>Check Now</a>
          </div>


      ),
    },
    {
      title: (
        <div className='plan_tab_ttl_inner_section_wrap'>
          <span class="checkmark_outer"><FontAwesomeIcon className="checkmark_outer_tab_icon" icon={faCheck} /><span className='checkmark_outer_tab_ttl'>Universe Plan</span>
           </span>
          <span class="plan_dis">Save 10%</span>
          <a href="#0">Learn More</a>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container">
   
          <div className='plan_tab_cnt_wrap'>
            <div className='plan_tab_cnt_head_wrap'>
              <span className='plan_tab_lft_ttl'>Details</span>
              <span className='plan_tab_rgt_ttl'>Universe Plan</span>
            </div>
            <div className='plan_tab_cnt_body_wrap'>
            <ul className='plan_tab_cnt_body_ul'>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">All Limited Links</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Own Analytic Platform</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des ttl_active">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn li_active"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
              <li className='plan_tab_cnt_body_li'>
              <span className="plan_tab_cnt_body_li_des">Chat Support</span>
              <span className="plan_tab_cnt_body_li_icn"><FontAwesomeIcon icon={faCheck} /></span>
              </li>
            </ul>
            </div>
          </div>
  
      
             <a href="#0" className='plan_tab_inr_btn'>Check Now</a>
          </div>


      ),
    }

  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (

    <div className='plan_tab_outer_wrap container'>
     
   
     <Box sx={{ maxWidth: { xs: 768, sm: 475 }, bgcolor: 'background.paper' }}>
     <div className="tab-header">
       <Tabs
         value={value}
         onChange={handleChange}
         variant="scrollable"
         scrollButtons
         allowScrollButtonsMobile
         aria-label="scrollable force tabs example"
       >
   
         {tabData.map((tab, index) => (
           <Tab key={index} label={tab.title} />
         ))}
       </Tabs>
       </div>
       {/* Display the content of the selected tab based on the value state */}
       <div className='tab-content'>{tabData[value].content}</div>
     </Box>
     </div>
   
  );

         }
export default PlanMonTabContentSection;
