import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import "./tabsection.css";
import ESO_Lan_B_ranking from './../../images/Images/Icon/B/ranking.png';
import ESO_Lan_W_ranking from './../../images/Images/Icon/W/ranking.png';
import ESO_Lan_M2 from './../../images/Images/M2.png';
import ESO_Lan_tab_right_bg from './../../images/Images/tab_right_bg.png';
import ESO_Lan_tab_robot from './../../images/Images/robot.png';
import ESO_Lan_W_link from './../../images/Images/Icon/W/link.png';
import ESO_Lan_B_link from './../../images/Images/Icon/B/link.png';
import ESO_Lan_W_analytics from './../../images/Images/Icon/W/analytics.png';
import ESO_Lan_B_analytics from './../../images/Images/Icon/B/analytics.png';
import ESO_Lan_W_performance from './../../images/Images/Icon/W/performance.png';
import ESO_Lan_B_performance from './../../images/Images/Icon/B/performance.png';
import ESO_Lan_W_Validator from './../../images/Images/Icon/W/Validator.png';
import ESO_Lan_B_Validator from './../../images/Images/Icon/B/Validator.png';
import ESO_Lan_W_sitemap from './../../images/Images/Icon/W/sitemap.png';
import ESO_Lan_B_sitemap from './../../images/Images/Icon/B/sitemap.png';



function ScrollableTabsButtonForce() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabData = [
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_ranking} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_ranking} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">On Page Analysis</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_lft">
          <div className='tab_cnt_left_wrap'>
                  <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          </div>
          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>
            On Page Analysis
            </h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Crawl a large volume of web pages</li>
                <li>Get Page-wise SEO health reports</li>
                <li>Extract SEO elements to find missing & duplicate elements</li>
                <li>Compare on-page SEO data with competitors</li>
                <li>Find Missing and Mismatched canonical URLs</li>
                <li>Verify Pagination & hreflang directives</li>
                <li>Set your own priorities for SEO issues</li>
                <li>Compare previous crawls, Staging & QA environments</li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>


        </div>
      ),
    },
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_link} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_link} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">Performance</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_rgt">

          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>
            Performance
            </h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Track User Experience scores of landing pages</li>
                <li>Assess coding standards of web pages</li>
                <li>Check AMP compliance, issues & remedies</li>
                <li>Check to fix Page load time, speed, image optimization</li>
                <li>Validate URL accessibility & Page SEO metrics</li>
                <li>White-labeled detailed audit report</li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>
          <div className='tab_cnt_left_wrap'>
                  <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          </div>

        </div>
      ),
    },
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_analytics} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_analytics} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">Accessibility</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_lft">
          <div className='tab_cnt_left_wrap'>
                  <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          </div>
          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>
            Accessibility
            </h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Test W3C, WCAG, ARIA accessibility</li>
                <li>Check Mobile accessibility score</li>
                <li>Create a more inclusive and user-friendly website</li>
                <li>Reduce the risk of legal action due to non-compliance with accessibility laws</li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>


        </div>
      ),
    },
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_performance} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_performance} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">Rank Analysis</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_rgt">

          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>
            Rank Analysis
            </h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Check the preferred page’s rank status</li>
                <li>Track a high-volume of keywords</li>
                <li>Check keyword performance in Mobile searches</li>
                <li>Compare with competitor’s rank</li>
                <li>Track website’s visibility score</li>
                <li>Compare search engine page-wise rank positions</li>
                <li>Track search engine rank for web pages by section</li>
                <li>Understand & define strategies using keyword rank trends</li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>
          <div className='tab_cnt_left_wrap'>
                  <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          </div>

        </div>
      ),
    },
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_Validator} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_Validator} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">Content Analysis</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_lft">
          <div className='tab_cnt_left_wrap'>
                  <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          </div>

          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>
            Content Analysis
            </h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Check clear language usage to be plain and simple to make content comprehensible</li>
                <li>Ensure headings and structure of page content</li>
                <li>Check website content for plagiarism</li>
                <li>Validate spelling and correct grammar  </li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>


        </div>
      ),
    },
    {
      title: (
        <div className='tab_ttl_inner_section_wrap'>
        <img src={ESO_Lan_B_sitemap} alt="tabIcons" className="tab_active_image" />
        <img src={ESO_Lan_W_sitemap} alt="tabIcons" className="tab_unactive_image" />
        <div class="tab_head_ttl">Off Page SEO</div>
      </div>
      ),
      content: (
        <div className="tab_cnt_inner_section_wrap tab_rank_checker_wrap container tab_cnt_rgt">

          <div className='tab_cnt_right_wrap'>
            <h5 className='tab_cnt_ttl_wrap'>Off Page SEO</h5>
            <div className='tab_cnt_right_innr_wrap'>
              <ul>
                <li>Audit Backlink Building and Analysis</li>
                <li>Verify Brand Mentions</li>
                <li>Check social media visibility (coming soon)</li>
                <li>Crawl and Scan Schema Markups</li>
              </ul>
              <img src={ESO_Lan_tab_robot} alt="robot image" className='tab_inner_img_robot' />
            </div>
             <a href="#0" className='tab_inr_btn'>Explore All Features</a>
          </div>
          <div className='tab_cnt_left_wrap'>
          <img src={ESO_Lan_M2} alt="rankchecker image" className='tab_right_image' />
          <img src={ESO_Lan_tab_right_bg} alt="rankchecker image" className='tab_right_btm_image' />
          
          </div>

        </div>
      ),
    },
 
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className='tab_outer_wrap container'>
     
   
    <Box sx={{ maxWidth: { xs: 768, sm: 475 }, bgcolor: 'background.paper' }}>
    <div className="tab-header">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >

        {tabData.map((tab, index) => (
          <Tab key={index} label={tab.title} />
        ))}
      </Tabs>
      </div>
      {/* Display the content of the selected tab based on the value state */}
      <div className='das_tab_container'>{tabData[value].content}</div>
    </Box>
    </div>
  );
}

export default ScrollableTabsButtonForce;
