import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './instand.css';
import animatiom_gif from '../../../images/Images/Login/Animation.gif';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom'
import { Oval } from 'react-loader-spinner';
import {useSearchParams} from 'react-router-dom';

function BussinessData() {

  const urlParams = new URLSearchParams(window.location.search);
  const url =localStorage.getItem('site_audit_url')

 




  const navigate = useNavigate();
  const [loading,setloading]=useState(false)
  const [errormsg,Seterrormsg]=useState('')




  document.body.style.background = 'linear-gradient(271deg, #442c7f, #ba4a9b) 0 0 transparent';
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
      const [formData, setFormData] = useState({
        Email: '',
        Name: '',
        BusinessName: '',
        country: '',
      });
      const [errors, setErrors] = useState({
        Email: '',
        Name: '',
        BusinessName: '',
        country: '',
      });

      // Refs for the input fields
    const EmailRef = useRef(null);
    const NameRef = useRef(null);
    const BusinessNameRef = useRef(null);
    const countryRef = useRef(null);
    // Function to handle changes in the input field

    // Function to handle changes in the input field
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      
        // Clear the specific field error when the user starts typing
        setErrors({
          ...errors,
          [name]: '',
        });
    };
      /**countries */

      useEffect(() => {
          // Fetch the list of countries from an API or use a hardcoded list
          // For simplicity, we are using a hardcoded list here.
          const fetchCountries = async () => {
            try {
              const response = await fetch('https://restcountries.com/v3.1/all');
              const data = await response.json();
      
              // Extract country names from the data
              const countryNames = data.map((country) => country.name.common);
              setCountries(countryNames);
            } catch (error) {
              console.error('Error fetching countries:', error);
            }
          };
      
          fetchCountries();
        }, []);
        const handleCountryChange = (event) => {
          const selectedCountryValue = event.target.value;
          setSelectedCountry(selectedCountryValue);
        };
    // Function to handle form submission
    const handleSubmit = async(e) => {
        e.preventDefault();

        // Validation logic
        const {Email,Name,BusinessName} = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newErrors = {};
        if (!Email || Email.length === 0) {
          newErrors.Email = "Please enter your Business email";
          // Focus on the email/phone input field if there's an error
          EmailRef.current.focus();
        } else if (!emailRegex.test(Email)) {
          newErrors.Email = "Please enter a valid Business email";
          // Focus on the email/phone input field if there's an error
          EmailRef.current.focus();
        }

        if (!Name || Name.length === 0) {
            newErrors.Name = "Please enter your unique name";
            // Focus on the email/phone input field if there's an error
            NameRef.current.focus();
          }

        if (!BusinessName || BusinessName.length === 0) {
            newErrors.BusinessName = "Please enter your unique Business name";
            // Focus on the email/phone input field if there's an error
            BusinessNameRef.current.focus();
          }
         if (!selectedCountry || selectedCountry.length === 0) {
            newErrors.country = "Please enter your country";
            // Focus on the email/phone input field if there's an error
            countryRef.current.focus();
          }

      
      
        setErrors(newErrors);
      
        // Determine the first invalid field and focus on it
        if (newErrors.Email) {
          EmailRef.current.focus();
        } else if (newErrors.Name) {
          NameRef.current.focus();
        } else if (newErrors.BusinessName) {
          BusinessNameRef.current.focus();
        } else if (newErrors.country) {
          countryRef.current.focus();
        }
      
        if (Object.values(newErrors).every((error) => error === '')) {


          try{
            setloading(true)
            Seterrormsg('')
            const Response=await axios.post(''+process.env.REACT_APP_API_V3_URL+'/landing_page/new_free_instant_report_send_otp/', {
              domain_url: url,
              email: Email,
              name: Name,
              business_name:BusinessName,
              country_id: 1,
          
          })

          if(Response.data)
          {

            localStorage.setItem('site_audit_email',Email)
            navigate('/otp-validation')
            setloading(false)
          }

          }catch(err)
          {
            Seterrormsg(err.response.data.error)
            setloading(false)
          }


     


      

        }
      };



    return (
        <>
        <div className='BussinessData_outer_wrap Bd_outer_container'>
        <div className='container'>
        <div className='BussinessData_Header'>
        <p>Please Enter your Business Information</p>
        </div>
        <div className='BussinessData_inner_container'>
        
        <div className='innr_cnt_wrap BussinessData_lft_wrap'>
        <form onSubmit={handleSubmit} className='otp_valid_frm'>
        <fieldset> 
        <label>Email Address</label>
        <div className='growing-fied-outer'> 
        <input type="text" onChange={handleInputChange} value={formData.Email} name="Email" placeholder='Sample@businessmail.com' className='bs_em_field' ref={EmailRef}/>
        <div className="growing-circle"></div></div>
        <div className="frm_error">{errors.Email}</div>
        </fieldset>
        <fieldset> 
        <label>Your Name</label>
        <div className='growing-fied-outer'> 
        <input type="text"  onChange={handleInputChange} value={formData.Name} name="Name" placeholder='Add a unique name' className='bs_nm_field' ref={NameRef}/>
        <div className="growing-circle"></div></div>
        <div className="frm_error">{errors.Name}</div>
        </fieldset>
        <fieldset> 
        <label>Business name</label>
        <div className='growing-fied-outer'> 
        <input type="text"  onChange={handleInputChange} value={formData.BusinessName} name="BusinessName" placeholder='Add a unique name' className='bs_bm_field' ref={BusinessNameRef}/>
        <div className="growing-circle"></div></div>
        <div className="frm_error">{errors.BusinessName}</div>
        </fieldset>
        <fieldset> 
        <label>Country</label>
        <div className='growing-fied-outer'> 
        <select id="country" value={selectedCountry} name="country" onChange={handleCountryChange} ref={countryRef}>
        <option value="" disabled className="Select_cntry_field">Select a country</option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
        <div className="growing-circle">
        <div class="icon"> <FontAwesomeIcon icon={faAngleDown} style={{ color: '#EF5513' }} /></div>
        </div></div>
        <div className="frm_error">{errors.country}</div>
        </fieldset>
      <button type="submit" className='otp_bt_smt'>Submit
      {
        loading===true ?   <Oval
        height={20}
        width={20}
        color="#fff"
        wrapperStyle={{}}
        wrapperClass="otp_bt_smt_loading"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      
      /> : null
      }
      
   


</button>

<div style={{color:'#ffae1f'}}>
{errormsg}
</div>
       </form>
        </div>
        <div className='innr_img_wrap BussinessData_rgt_wrap'>
        <img src={animatiom_gif} alt="gif image"/>
        </div>

        </div>
        </div>
        </div>
        
        </>
    );
}

export default BussinessData;